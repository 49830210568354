import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import HomeOurTeam from "../Home/Home/HomeOurTeam/HomeOurTeam";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const InvestigacaoInfertilidade = () => {
  const title = "Investigação de Infertilidade";
  const descricao =
    "Nossa missão é fornecer um ambiente acolhedor e de confiança, onde o casal se sinta apoiado em cada passo do processo de investigação da infertilidade.";
  const price = "R$ 359,00 ou até 6x de R$ 69,90";

  const fatos = {
    titulo_fatos: "Juntas nesse desafio",
    descricao_fatos:
      "Enfrentar dificuldades para conceber pode ser um desafio emocional e físico para qualquer casal. Nosso serviço de pesquisa de infertilidade conjugal está aqui para oferecer o suporte e a expertise necessários para transformar esse sonho em realidade",
    subtitulos: [
      {
        titulo: "Cada casal é único",
        subtitulo:
          "Com uma abordagem personalizada e cuidadosa, buscamos compreender as necessidades específicas de cada casal, realizando uma análise detalhada e individualizada para identificar possíveis causas da infertilidade.",
      },
      {
        titulo: "Conte com nossa ajuda",
        subtitulo:
          "Porque juntas, podemos transformar desafios em conquistas. Entre em contato conosco hoje mesmo para dar o primeiro passo em direção à realização do seu desejo de ter um filho.",
      },
    ],
  };

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Envio de exames prévios para nosso link de whatsapp se houverem",
      descricao: "Receba o link de atendimento",
    },

    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Consulta Online (~45 min)",
      descricao: "Aguarde o retorno",
    },
    {
      titulo:
        "Envio de exames prévios via link recebido pelo WhatsApp, se houverem",
      descricao: "Realize os exames",
    },
    {
      titulo: "Retorno em até 30 dias para ver seus exames.",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Envio de relatório Médico",
      descricao: "Receba o relatório médico",
    },
  ];

  const idArea = 1;
  const idServico = 6;

  const imgUrl = "img/services/6.Infertilidade.png";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="About Us" subtitle="About" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />{" "}
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}
      <HomeThreeFaq />
      <HomeFooter />
    </>
  );
};

export default InvestigacaoInfertilidade;
