import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import "./formStyles.scss";

// Função para remover caracteres não numéricos
const sanitizeNumber = (value) => {
  if (value && typeof value === "string") {
    return value.replace(/\D/g, ""); // Apenas sanitiza se o valor for uma string
  }
  return ""; // Retorna vazio se não for uma string válida
};

// Componentização dos campos de entrada
const InputField = ({
  label,
  name,
  value,
  onChange,
  error,
  type = "text",
  ...props
}) => (
  <div className="calculate-form appointment-form-3 mb-20">
    <label htmlFor={name}>{label}</label>
    <input
      id={name}
      type={type}
      value={value} // Garantimos que o valor esteja vindo do estado
      onChange={onChange} // Atualiza o estado ao digitar
      aria-invalid={error ? "true" : "false"}
      className={error ? "input-error" : ""} // Aplica a classe de erro se houver
      {...props}
    />
    {error && (
      <span role="alert" className="error">
        {error}
      </span>
    )}
  </div>
);

const MaskedInputField = ({
  label,
  name,
  value,
  onChange,
  mask,
  error,
  ...props
}) => (
  <div className="calculate-form appointment-form-3 mb-20">
    <label htmlFor={name}>{label}</label>
    <InputMask
      id={name}
      mask={mask}
      value={value} // Certifique-se de que o valor está no estado correto
      onChange={onChange} // Atualiza o estado corretamente ao mudar
      className={error ? "input-error" : ""} // Aplica a classe de erro se houver
      {...props}
    />
    {error && (
      <span role="alert" className="error">
        {error}
      </span>
    )}
  </div>
);

const StepThree = ({
  nome,
  setNome,
  email,
  setEmail,
  cpf,
  setCpf,
  telefone,
  setTelefone,
  cep,
  setCep,
  logradouro,
  setLogradouro,
  bairro,
  setBairro,
  cidade,
  setCidade,
  estado,
  setEstado,
  numero,
  setNumero,
  mensagem,
  setMensagem,
  nextStep,
  prevStep,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState({});

  const handleCepBlur = async (e) => {
    const cepValue = sanitizeNumber(e.target.value);
    if (cepValue.length === 8) {
      try {
        const response = await fetch(
          `https://viacep.com.br/ws/${cepValue}/json/`
        );
        const data = await response.json();
        if (!data.erro) {
          setLogradouro(data.logradouro);
          setBairro(data.bairro);
          setCidade(data.localidade);
          setEstado(data.uf);
        } else {
          setMessage("CEP não encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar o CEP:", error);
        setMessage("Erro ao buscar o CEP.");
      }
    }
  };

  // Função para validar o formulário
  const validateForm = () => {
    const newErrors = {};

    // Validações manuais
    if (!nome.trim()) newErrors.nome = "Nome é obrigatório";
    if (!email.includes("@")) newErrors.email = "Email inválido";
    if (sanitizeNumber(cpf).length !== 11) newErrors.cpf = "CPF inválido";
    if (sanitizeNumber(telefone).length !== 11)
      newErrors.telefone = "Telefone inválido";
    if (sanitizeNumber(cep).length !== 8) newErrors.cep = "CEP inválido";
    if (!logradouro.trim()) newErrors.logradouro = "Logradouro é obrigatório";
    if (!bairro.trim()) newErrors.bairro = "Bairro é obrigatório";
    if (!cidade.trim()) newErrors.cidade = "Cidade é obrigatória";
    if (!estado.trim()) newErrors.estado = "Estado é obrigatório";
    if (!numero.trim()) newErrors.numero = "Número é obrigatório";

    setErrors(newErrors);

    // Se não houver erros, o formulário está válido
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // Se o formulário for inválido, interrompa o envio
    if (!validateForm()) {
      return;
    }

    const formData = {
      nome,
      email,
      cpf: sanitizeNumber(cpf),
      telefone: sanitizeNumber(telefone),
      cep: sanitizeNumber(cep),
      logradouro,
      bairro,
      cidade,
      estado,
      numero,
      mensagem,
    };

    setIsSubmitting(true);

    console.log("Formulário enviado com os dados:", formData);

    // Simula o envio de dados com um delay
    setTimeout(() => {
      setIsSubmitting(false);
      setMessage("Formulário enviado com sucesso!");
      nextStep();
    }, 2000);
  };

  return (
    <>
      {isSubmitting ? (
        <div className="loader">Enviando...</div>
      ) : (
        <form onSubmit={onSubmit} className="row">
          {message && (
            <div className={`message ${isSubmitting ? "loading" : "success"}`}>
              {message}
            </div>
          )}

          <div className="col-xl-6 col-lg-6 col-md-6">
            <InputField
              label="Nome"
              name="nome"
              value={nome} // Valor vindo do estado
              onChange={(e) => setNome(e.target.value)} // Atualiza o estado
              error={errors.nome}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <InputField
              label="Email"
              name="email"
              type="email"
              value={email} // Valor vindo do estado
              onChange={(e) => setEmail(e.target.value)} // Atualiza o estado
              error={errors.email}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <MaskedInputField
              label="CPF"
              name="cpf"
              mask="999.999.999-99"
              value={cpf} // Valor vindo do estado
              onChange={(e) => setCpf(e.target.value)} // Atualiza o estado
              error={errors.cpf}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <MaskedInputField
              label="Telefone"
              name="telefone"
              mask="(99) 99999-9999"
              value={telefone} // Valor vindo do estado
              onChange={(e) => setTelefone(e.target.value)} // Atualiza o estado
              error={errors.telefone}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <MaskedInputField
              label="CEP"
              name="cep"
              mask="99999-999"
              value={cep} // Valor vindo do estado
              onChange={(e) => setCep(e.target.value)} // Atualiza o estado
              onBlur={handleCepBlur}
              error={errors.cep}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <InputField
              label="Logradouro"
              name="logradouro"
              value={logradouro} // Valor vindo do estado
              onChange={(e) => setLogradouro(e.target.value)} // Atualiza o estado
              error={errors.logradouro}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <InputField
              label="Bairro"
              name="bairro"
              value={bairro} // Valor vindo do estado
              onChange={(e) => setBairro(e.target.value)} // Atualiza o estado
              error={errors.bairro}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <InputField
              label="Cidade"
              name="cidade"
              value={cidade} // Valor vindo do estado
              onChange={(e) => setCidade(e.target.value)} // Atualiza o estado
              error={errors.cidade}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <InputField
              label="Estado"
              name="estado"
              value={estado} // Valor vindo do estado
              onChange={(e) => setEstado(e.target.value)} // Atualiza o estado
              error={errors.estado}
            />
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6">
            <InputField
              label="Número"
              name="numero"
              value={numero} // Valor vindo do estado
              onChange={(e) => setNumero(e.target.value)} // Atualiza o estado
              error={errors.numero}
            />
          </div>

          <div className="col-xl-12">
            <div className="calculate-form appointment-form-3 mb-20">
              <label htmlFor="mensagem">Mensagem</label>
              <textarea
                id="mensagem"
                cols="30"
                rows="10"
                value={mensagem} // Certifique-se de que o valor vem do estado
                onChange={(e) => setMensagem(e.target.value)} // Atualiza o estado ao digitar
                placeholder="Caso deseje nos informar algo, fique à vontade."></textarea>
            </div>
          </div>

          <button className="primary_btn mt-40" type="submit">
            Próximo
          </button>
          <button className="secondary_btn mt-40" onClick={prevStep}>
            Anterior
          </button>
        </form>
      )}
    </>
  );
};

export default StepThree;
