import React from "react";

const HomeFourSingleHowWorksItem = ({ icon, title, subtitle, moveIcon }) => {
  return (
    <>
      <div className={`col-lg-3 col-md-4 ${moveIcon && "pos-rel"}`}>
        <div className="howit-box text-center mb-40">
          <i>
            <img src={`img/icon/${icon}.png`} alt="" />
          </i>
          <h3>{title}</h3>
          <p>{subtitle}</p>
          {moveIcon && (
            <img
              src="img/home4/how/move-icon.png"
              alt=""
              className={moveIcon ? "move-icon" : ""}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default HomeFourSingleHowWorksItem;
