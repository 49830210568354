import React from "react";
import { Link } from "react-router-dom";

const HomeFact = ({ fatos }) => {
  return (
    <>
      <section className="fact-area  primary-bg pos-rel pt-115 pb-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-10">
              <div className="section-title pos-rel mb-45">
                <div className="section-text section-text-white pos-rel">
                  <h1 className="" style={{ color: "#212121" }}>
                    {fatos.titulo_fatos}{" "}
                  </h1>
                  <p style={{ color: "#212121" }}>{fatos.descricao_fatos}</p>
                </div>
              </div>
              {/* <div className="section-button section-button-left mb-30">
                <Link to="/contact" className="primary_btn btn-icon ml-0">
                  <span>+</span>Agende agora
                </Link>
              </div> */}
            </div>
            <div className="col-lg-6 col-lg-6 col-md-8">
              <div className="cta-satisfied">
                {fatos.subtitulos.map((subtitulo, index) => (
                  <div key={index} className="single-satisfied mb-50">
                    <h5
                      style={{
                        color: "#dd5cac",
                        fontStyle: "italic",
                        fontWeight: "bold",
                      }}
                    >
                      {subtitulo.titulo}
                    </h5>
                    <p style={{ color: "#212121" }}>{subtitulo.subtitulo}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFact;
