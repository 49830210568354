import React from "react";

const HomeFourAbout = () => {
  return (
    <>
      <section className="about-area pt-130 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5">
              <div className="h4about-thumb pos-rel">
                <img src="img/contato.png" alt="" />
                {/* <a href="#" className="call-btn f-700 white-color green-bg">
                  <i className="call-icon">
                    <img src="img/home4/icon/about__phone__icon.png" alt="" />
                  </i>
                  <span>Call : 012 (963) 15632</span>
                </a> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-7">
              <div className="about-right-side h4about-right mb-30">
                <div className="about-title mb-50">
                  {/* <h5 className="pink-color">Todo Cuidado</h5> */}
                  <h1>Resgate seu poder feminino</h1>
                </div>
                <div className="about-text">
                  <p className="theme-color">
                    Estamos aqui para facilitar a vida da mulher moderna,
                    garantindo seu acesso à saúde de onde ela estiver.
                    Acreditamos que a telemedicina proporciona autonomia,
                    liberdade para buscar saúde de forma fácil, integrada e
                    igualmente humanizada.
                  </p>
                </div>
                <div
                  className="about-author d-flex align-items-center"
                  style={{ marginTop: "50px" }}
                >
                  {/* <div className="author-ava h4author-ava">
                    <img src="img/avatar.png" alt="" />
                  </div> */}
                  <div className="author-desination h4author-destination">
                    <p
                      style={{
                        fontStyle: "italic",
                        fontSize: "1rem",
                        textAlign: "right",
                      }}
                    >
                      "Precisava falar com uma ginecologista para escolher meu
                      método anticoncepcional, confesso que inicialmente fiquei
                      insegura pelo atendimento ser online. Mas, foi uma escolha
                      acertada, gostei da facilidade para acessar as médicas da
                      plataforma, além do atendimento ter sido muito empático.
                      Me senti como numa consulta presencial, mas sem sair de
                      casa! Amei!"
                    </p>
                    <h4 className="mb-0" style={{ textAlign: "right" }}>
                      Marina Moraes, 24 anos
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFourAbout;
