import React from "react";
import { Offcanvas } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Collapsible from "react-collapsible";

const Sidebar = ({ show, handleClose }) => {
  return (
    <>
      <div>
        <Offcanvas
          show={show}
          onHide={handleClose}
          placement="end"
          className="side__bar"
        >
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <Collapsible
              trigger="Início"
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="/">Página Inicial</NavLink>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger="Ginecologia"
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="/anticoncepcao">Anticoncepção</NavLink>
                </li>
                <li>
                  <NavLink to="/insercaoTrocaDIUsImplanon">
                    Inserção / Troca DIUs e Implanon
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/primeiraConsulta">
                    Primeira consulta ginecológica
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/rotinaGinecologica">
                    Rotina ginecológica
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sosGinecologistaOnline">
                    S.O.S Ginecologista
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/avaliacaoFertilidade">
                    Avaliação da fertilidade
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/investigacaoInfertilidade">
                    Investigação da infertilidade
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/reposicaoHormonal">
                    Reposição hormonal na menopausa
                  </NavLink>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger="Obstetrícia"
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="/consultoriaPreNatal">
                    Pré-Natal à Distância
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/suplementacaoPreGestacional">
                    Suplementação Pré Gestacional
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/suplementacaoNaGestacao">
                    Suplementação Na Gestação
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/suplementacaoPosParto">
                    Suplementação Pós Parto
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/diabetesGestacional">
                    Consultoria em Diabetes Gestacional
                  </NavLink>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger="Como funciona"
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <NavLink to="#howitworks">Entenda o processo</NavLink>
                </li>
              </ul>
            </Collapsible>

            <Collapsible
              trigger="Contato"
              triggerTagName="div"
              triggerOpenedClassName="icon_close"
              triggerClassName="iconAdd"
              open={false}
            >
              <ul className="sidebar_sub_menu text-white mt-3">
                <li>
                  <a href="https://wa.me/11958799090">WhatsApp</a>
                </li>
                <li>
                  <NavLink to="/contact">Fale Conosco</NavLink>
                </li>
              </ul>
            </Collapsible>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default Sidebar;
