import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";

import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import AboutCounter from "./AboutCounter/AboutCounter";
import AboutTestimonial from "./AboutTestimonial/AboutTestimonial";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const Anticoncepcao = () => {
  const title = "Anticoncepção";
  const descricao =
    "<i>Toda mulher Olis tem o poder de decisão sobre seu corpo.</i> </br></br>Decidir sobre seu futuro reprodutivo, se quer ou não ter filhos e quando é o melhor momento é uma conquista nossa!";
  const price = "R$ 359,99 ou até 6x de R$ 69,99";

  const fatos = {
    titulo_fatos: "Conhecimento é poder.",
    descricao_fatos:
      "Te ajudaremos a encontrar qual é o melhor método anticoncepcional para você.  Conversaremos sobre os métodos comportamentais,  opções de DIU - hormonal ou não-, pílulas anticoncepcionais, implanon e vários outros.",
    subtitulos: [
      {
        titulo: "Seu corpo, suas regras.",
        subtitulo:
          "Você sairá da nossa conversa segura e decidida quanto ao método escolhido. Você estará nos centros das decisões sobre seu corpo.",
      },
      {
        titulo: "Livre, leve e solta!",
        subtitulo:
          "Sabemos que a boa escolha do metodo contraceptivo traz mais segurança na relação com seu parceiro, deixando esse momento ainda melhor, sem prejuízos a sua libido! ",
      },
    ],
  };

  const idArea = 1;
  const idServico = 3;

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo:
        "Envio de exames prévios via link recebido pelo WhatsApp, se houverem",
    },
    {
      titulo: "Consulta por videochamada (~ 45min)",
      descricao: "Converse com a gente",
    },

    {
      titulo:
        "Solicitação de exames e envio de prescrição médica digital, se necessários",
      descricao: "Realize os exames",
    },
    {
      titulo: "Retorno em até 30 dias para ver seus exames.",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Envio de Relatório Médico",
      descricao: "Receba o relatório médico",
    },
  ];

  const imgUrl = "img/services/4._Anticoncepcional_.png";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="About Us" subtitle="About" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />

      <HomeThreeFaq />

      {/* <HomeOurTeam /> */}
      {/* <AboutTestimonial /> */}
      {/* <AboutAnalysis /> */}

      <HomeFooter />
    </>
  );
};

export default Anticoncepcao;
