import React from "react";

const InformedConsent = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1>
            Termo de Consentimento Informado Eletrônico para Consulta à
            Distância
          </h1>

          <p>
            Eu dou plena autorização ao médico da Olis Saúde para realizar o
            atendimento através de serviços eletrônicos de consulta à distância.
            Declaro que fui informado(a) de forma clara e compreensível sobre os
            procedimentos e condições da consulta à distância que será realizada
            através de uma plataforma online.
          </p>

          <h2>Declaro ter ciência que:</h2>
          <ul>
            <li>
              Entendo que a consulta será conduzida remotamente, utilizando
              tecnologias de comunicação online, como videoconferência, para
              permitir a interação médico-paciente à distância.
            </li>
            <li>
              Compreendo que a consulta à distância tem como finalidade a
              avaliação, diagnóstico, orientação e/ou acompanhamento
              relacionados à minha saúde.
            </li>
            <li>
              (I) Para o bom aproveitamento e análise do Médico devo informar
              todos os dados possíveis, não omitindo nenhuma informação acerca
              dos meus sintomas.
            </li>
            <li>
              (II) Me comprometo com a veracidade das minhas informações em
              virtude da ausência de avaliação clínica presencial pelo Médico.
            </li>
            <li>
              (III) Reconheço que, devido à natureza remota da consulta, o
              médico não poderá realizar um exame físico completo. Entendo que
              algumas condições médicas podem requerer avaliação presencial.
              Sendo assim, poderei ser encaminhada, segundo a avaliação do
              médico, para uma consulta presencial ou serviço de saúde.
            </li>
            <li>
              (IV) Entendo que poderá ser necessária a complementação com exames
              para um melhor diagnóstico.
            </li>
            <li>
              (V) Estou ciente que mesmo após a consulta poderá ser necessária a
              minha presença ao consultório ou que, eventualmente, o médico
              poderá me encaminhar a algum serviço de urgência a depender da
              hipótese diagnóstica.
            </li>
            <li>
              (VI) Sei que a consulta eletrônica é pontual e não garante ao
              Paciente o direito ao atendimento por tempo indeterminado ou a
              disposição do médico em outros horários não acordados previamente
              entre as partes.
            </li>
            <li>
              (VII) Reconheço que, embora a consulta à distância possa oferecer
              conveniência, existem limitações técnicas e de segurança inerentes
              ao uso de tecnologias online, tais como interrupções na conexão ou
              falhas técnicas que poderiam afetar a qualidade da comunicação.
            </li>
            <li>
              (VIII) Autorizo a gravação dessa consulta. Estou ciente de que
              serão tomadas medidas razoáveis para garantir a confidencialidade
              das informações transmitidas durante a consulta à distância,
              conforme exigido pela legislação aplicável e as melhores práticas
              em proteção de dados. Em hipótese nenhuma haverá divulgação desse
              conteúdo, exceto para fins jurídicos, se necessário.
            </li>
            <li>
              (IX) Autorizo o médico responsável pela consulta à distância em
              ginecologia e obstetrícia a realizar avaliações, diagnósticos e
              recomendações terapêuticas baseadas na interação remota,
              entendendo que tais decisões serão tomadas com base nas
              informações disponíveis e na melhor prática clínica.
            </li>
            <li>
              (X) Reconheço que tenho o direito de recusar qualquer parte do
              tratamento proposto, sem que isso afete outros cuidados médicos
              que eu possa receber.
            </li>
            <li>
              (XI) Fui informado(a) que o pagamento foi realizado à plataforma
              para este atendimento específico. Entendo que este pagamento cobre
              apenas a consulta atual e o retorno referente a esta sessão
              específica, quando houver. Qualquer consulta subsequente
              necessitará de um novo pagamento conforme as políticas da
              plataforma ou do profissional de saúde.
            </li>
          </ul>

          <p>
            Indico que li, compreendi e concordo com os termos descritos acima.
            Declaro ainda ter lido as informações e orientações contidas no
            presente instrumento, as quais entendi perfeitamente e aceito.
          </p>
          <p>
            Assim, expresso meu pleno consentimento para a realização da
            teleconsulta.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformedConsent;
