import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const ConsultoriaPreNatal = () => {
  const title = "Consultoria Pré-Natal à Distância";
  const descricao =
    "Para garantir que você, mulher Olis, e seu bebê recebam o melhor cuidado possível, oferecemos nosso serviço de pré-natal a distância, combinando conveniência e atenção médica especializada sem a necessidade de sair de casa.";
  const price = "";

  const fatos = {
    titulo_fatos: "Seu Pré-Natal com Conforto e Segurança",
    descricao_fatos:
      "Com nosso serviço de pré-natal à distância, você pode se concentrar em aproveitar sua gestação, sabendo que está recebendo o melhor cuidado possível. Garantimos que você e seu bebê estejam sempre em boas mãos, com suporte especializado a qualquer hora e em qualquer lugar.",
    subtitulos: [
      {
        titulo: "Feito especialmente para você",
        subtitulo:
          "Para mães Olis de qualquer país ou região que buscam conveniência e flexibilidade no acompanhamento pré-natal; priorizam uma equipe atualizada com base em evidências científicas e desejam minimizar visitas presenciais a clínicas ou hospitais.",
      },
      {
        titulo: "Informação é poder",
        subtitulo:
          " Tire dúvidas de exames de seguimento pré natal, faça acompanhamento do seu ganho de peso e do seu bebê, converse sem medo sobre as vias de parto e  métodos de indução. Agende sua consulta e prepare-se para ser a protagonista desse  momento especial.",
      },
    ],
  };

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp via WhatsApp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Envio de exames prévios via WhatsApp, se houver",
      descricao: "Receba o link de atendimento",
    },

    {
      titulo: "Consulta realizada por videochamada (~60 min)",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Solicitação de exames, se necessário",
      descricao: "Realize os exames",
    },
    {
      titulo: "Envio de relatório Médico",
      descricao: "Receba o relatório médico",
    },
  ];

  const pacotes = [
    {
      titulo: "Pacote 1",
      descricao:
        "Uma consultoria avulsa. Nesta opção, você fica à vontade para escolher o momento de uma consultoria. Conversaremos por 1 hora.",
      preco: "350",
      url: "/agendamento/2/21",
    },
    {
      titulo: "Pacote 2",
      descricao:
        "Duas consultorias, com intervalo mínimo de um mês. Conversaremos por 1 hora em cada consultoria.",
      preco: "650",
      url: "/agendamento/2/22",
    },
    {
      titulo: "Pacote 3",
      descricao:
        "<ul><li>Uma consultoria no primeiro trimestre.</li><li>Duas no segundo trimestre</li> <li>Três no terceiro trimestre.</li></ul><br/>Consideramos esse pacote perfeito para firmarmos uma relação de confiança personalizada e te deixarmos segura para a gestação. Você ganhará um guia básico para gestantes, preparado pela sua médica, especialmente para sua gestação.",
      preco: "1800",
      url: "/agendamento/2/23",
    },
  ];

  const idArea = 2; // Ajuste o ID conforme necessário
  const idServico = 7; // Ajuste o ID conforme necessário

  const imgUrl = "img/services/imagemprenatal.png";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="Consultoria Pré-Natal" subtitle="Serviço" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        pacotes={pacotes}
        imgUrl={imgUrl}
      />
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}
      <HomeThreeFaq />

      <HomeFooter />
    </>
  );
};

export default ConsultoriaPreNatal;
