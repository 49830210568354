import React, { useEffect, useState } from "react";

const StepOne = ({
  data,
  setData,
  horario,
  setHorario,
  horarios,
  nextStep,
}) => {
  const [maxDate, setMaxDate] = useState("");

  useEffect(() => {
    // Calcula a data máxima (30 dias a partir de hoje)
    const today = new Date();
    const maxDateObj = new Date(today);
    maxDateObj.setDate(today.getDate() + 30); // Adiciona 30 dias
    const formattedMaxDate = maxDateObj.toISOString().split("T")[0]; // Formato YYYY-MM-DD
    setMaxDate(formattedMaxDate);
  }, []);

  return (
    <>
      <div className="col-xl-6 col-lg-6 col-md-6">
        <label>Selecione uma data</label>
        <form className="calculate-form appointment-form-3 mb-20">
          <input
            name="date"
            value={data}
            onChange={(e) => setData(e.target.value)}
            type="date"
            placeholder="Selecione uma data"
            max={maxDate} // Define a data máxima
          />
        </form>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6">
        <label>Selecione um horário</label>
        <select
          value={horario}
          name="time"
          onChange={(e) => setHorario(e.target.value)}
          className="form-select select_style"
          aria-label="Default select example"
        >
          {horarios.map((item) => (
            <option key={item.id} value={item.name}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <button className="primary_btn mt-40" onClick={nextStep}>
        Próximo
      </button>
    </>
  );
};

export default StepOne;
