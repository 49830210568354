import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import HomeOurTeam from "../Home/Home/HomeOurTeam/HomeOurTeam";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import AboutCounter from "./AboutCounter/AboutCounter";
import AboutTestimonial from "./AboutTestimonial/AboutTestimonial";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";
const AvaliacaoFertilidade = () => {
  const title = "Avaliação de Fertilidade";
  const descricao =
    "<i>Conhecer sua fertilidade é o primeiro passo para sua liberdade reprodutiva.</i> </br></br>Indicado para Mulher Olis que deseja obter uma visão clara sobre seu potencial fértil, ou que planejam uma gravidez futura  ou que quer se preparar adequadamente.";
  const price = "R$ 299,00 ou até 6x de R$ 49,90";

  const fatos = {
    titulo_fatos: "Invista no Seu Futuro",
    descricao_fatos:
      "Sua saúde reprodutiva é fundamental para suas escolhas de vida. Não deixe dúvidas ou incertezas atrapalharem seus planos. ",
    subtitulos: [
      {
        titulo: "Conhecer para Planejar",
        subtitulo:
          "Tenha uma visão abrangente da sua saúde reprodutiva por meio de exames e avaliação médica especializada  para tomar decisões informadas sobre sua jornada para a maternidade.",
      },
      {
        titulo: "Realize seus sonhos",
        subtitulo:
          "Porque entender seu corpo é o primeiro passo para realizar seus sonhos.",
      },
    ],
  };

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo:
        "Solicitação de exames e envio de prescrição médica digital, se necessários",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Consulta realizada por videochamada (~30min)",
      descricao: "Converse com a gente",
    },

    {
      titulo: "Retorno para ver seus exames",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Envio de relatório Médico",
      descricao: "Receba o relatório médico",
    },
  ];

  const imgUrl = "img/services/DIUs_Implanon.png";

  const idArea = 1;
  const idServico = 5;

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="About Us" subtitle="About" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}

      <HomeThreeFaq />
      <HomeFooter />
    </>
  );
};

export default AvaliacaoFertilidade;
