import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import PrivacyPolicy from "../Blogs/BlogsArea/PrivacyPolicy";
import HomeHeroSection from "../Home/Home/HomeHeroSection/HomeHeroSection";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";

const PoliticaPrivacidade = () => {
  return (
    <>
      <HomeHeader />
      <HomeHeroSection />
      <PrivacyPolicy />
      <HomeFooter />
    </>
  );
};

export default PoliticaPrivacidade;
