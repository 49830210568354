import React from "react";

const HomeThreeSingleFaq = ({ number, isOpen, onClick, question, answer }) => {
  return (
    <div className="card">
      <div className="card-header" id={`heading${number}`}>
        <h5 className="mb-0">
          <a
            href="#"
            className={`btn-link ${isOpen ? "" : "collapsed"}`}
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${number}`}
            aria-controls={`collapse${number}`}
            aria-expanded={isOpen}
          >
            {question}
          </a>
        </h5>
      </div>
      <div
        id={`collapse${number}`}
        className={`collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`heading${number}`}
        data-parent="#accordion"
      >
        <div className="card-body">
          <p
            dangerouslySetInnerHTML={{
              __html: answer,
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

export default HomeThreeSingleFaq;
