import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";
import AboutTestimonial from "./AboutTestimonial/AboutTestimonial";

const SuplementacaoPosParto = () => {
  const title = "Suplementação Pós-Parto";
  const descricao =
    "A mulher Olis sabe que uma boa nutrição é o grande pilar para um pós-parto saudável. Ela fornece os nutrientes necessários para reparar tecidos, fortalecer o sistema imunológico e promover a produção de leite materno.";
  const price = "R$399,00 ou 6x R$69,90";

  const fatos = {
    titulo_fatos: "Renove-se após o parto",
    descricao_fatos:
      "A mulher Olis sabe que uma boa nutrição é o grande pilar para um pós parto saudável. Ela fornece os nutrientes necessários para reparar tecidos, fortalecer o sistema imunológico e promover a produção de leite materno.",
    subtitulos: [
      {
        titulo: "Amamente livremente",
        subtitulo:
          "Certos nutrientes, como vitaminas do complexo B e vitamina D, são importantes para a produção de leite materno de qualidade. A adequada  suplementação pode garantir que você tenha os nutrientes necessários para te ajudar no desafio da amamentação.",
      },
      {
        titulo: "Auto cuidado",
        subtitulo:
          "Cuidar da própria saúde, incluindo a suplementação de micronutrientes, é uma forma de autocuidado.  Você irá se sentir melhor física e emocionalmente durante essa fase de transição e grandes mudanças.",
      },
    ],
  };

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento do link de atendimento, via Whatsapp",
    },
    {
      titulo: "Consulta realizada por videochamada (~45 min)",
      descricao: "Aguarde o retorno",
    },
    {
      titulo:
        "Solicitação de exames e envio de prescrição médica, se necessário",
    },
    {
      titulo: "Recebimento da solicitação de exames laboratoriais",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Com resultado dos seus exames em mãos, agende a sua avaliação",
      descricao: "Receba o link de atendimento",
    },

    {
      titulo: "Recebimento da suplementação de micronutrientes via WhatsApp",
      descricao: "Realize os exames",
    },
    {
      titulo: "Acompanhamento via WhatsApp por 15 dias após consulta",
      descricao: "Receba o relatório médico",
    },
  ];

  const idArea = 2; // Ajuste o ID conforme necessário
  const idServico = 10; // Ajuste o ID conforme necessário

  const imgUrl = "img/services/Suplementacao.png";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="Suplementação Pós-Parto" subtitle="Serviço" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}
      <AboutTestimonial />
      <HomeThreeFaq />
      <HomeFooter />
    </>
  );
};

export default SuplementacaoPosParto;
