import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const SuplementacaoPreGestacional = () => {
  const title = "Suplementação Pré-Gestacional";
  const descricao =
    "Os 1.100 dias mais importantes da nutrição: uma janela de oportunidades para a saúde e o desenvolvimento do seu bebê. Alcance o potencial genético das próximas gerações, se preparando desde o período pré-concepcional, gestacional e pós-parto.";
  const price = "R$ 399,00 ou 6x de R$50,00";

  const fatos = {
    titulo_fatos: "Gestação segura",
    descricao_fatos:
      "A mulher Olis entende a necessidade de um bom estado nutricional, já que o consumo inadequado de nutrientes pode aumentar o risco de defeitos congênitos para o futuro concepto, colocando a sua gestação em risco.",
    subtitulos: [
      {
        titulo: "Saúde em foco",
        subtitulo:
          "Garantimos uma abordagem cuidadosa para que você e seu futuro bebê recebam os nutrientes essenciais necessários para preparar o seu organismo para uma gestação saudável e garantir um bom desenvolvimento fetal.",
      },
      {
        titulo: "Em busca dos seus sonhos",
        subtitulo:
          "Com a orientação de especialistas, você pode ter confiança de que está dando o melhor começo possível para sua jornada rumo à maternidade.",
      },
    ],
  };

  const passos = [
    {
      titulo: "Escolha do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento da solicitação de exames laboratoriais",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Com resultado dos seus exames em mãos, agende a sua consulta",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Consulta realizada por videochamada (45 min)",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Recebimento da suplementação de micronutrientes via WhatsApp",
      descricao: "Realize os exames",
    },
    {
      titulo: "Acompanhamento via WhatsApp por 15 dias após consulta",
      descricao: "Receba o relatório médico",
    },
  ];

  const idArea = 2; // Ajuste o ID conforme necessário
  const idServico = 8; // Ajuste o ID conforme necessário

  const imgUrl = "img/services/Suplementacao.png";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="Suplementação na Gestação" subtitle="Serviço" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}
      <HomeThreeFaq />

      <HomeFooter />
    </>
  );
};

export default SuplementacaoPreGestacional;
