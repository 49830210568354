import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";

import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import AboutCounter from "./AboutCounter/AboutCounter";
import AboutTestimonial from "./AboutTestimonial/AboutTestimonial";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const SOSGinecologistaOnline = () => {
  const title = "SOS Ginecologista Online";
  const descricao =
    "Porque sua saúde não pode esperar. Consulta rápida para aquele momento de aperto da mulher Olis. Conecte-se com uma ginecologista especialista agora e obtenha o cuidado que você merece, exatamente quando precisa.";
  const price = "R$ 199,00 ou até 6x de R$ 39,90";

  const fatos = {
    titulo_fatos: "Encontre a solução para possíveis problemas",
    descricao_fatos:
      "Te ajudaremos com leituras e/ou pedidos de exames de urgência, como ultrassom transvaginal e ultrassom de mama. Se apresentar sintomas como corrimentos genitais, coceiras ou cheiros atípicos, cólicas menstruais, sangramentos irregulares ou gestacionais, vamos te ajudar a aliviar esses e outros sintomas e tratar as possíveis causas.",
    subtitulos: [
      {
        titulo: "Conveniência Máxima",
        subtitulo:
          "Evite filas e salas de espera. Agende uma consulta online e fale com uma médica através de videochamada, no conforto e segurança do seu lar.",
      },
      {
        titulo: "Aconselhamento Imediato",
        subtitulo:
          "Receba orientações claras e precisas sobre os próximos passos, tratamentos ou cuidados necessários, ajudando a aliviar a ansiedade e resolver a situação de emergência rapidamente.",
      },
    ],
  };

  const idArea = 1;
  const idServico = 4;

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Envio de exames prévios via WhatsApp, se houverem",
    },
    {
      titulo: "Consulta realizada por videochamada (~ 30 min)",
      descricao: "Converse com a gente",
    },
    {
      titulo:
        "Envio de exames prévios via link recebido pelo WhatsApp, se houverem",
      descricao: "Realize os exames",
    },
    {
      titulo:
        "Agendamento do retorno em até 15 dias para ver resultados de exames",
      descricao: "Aguarde o acompanhamento",
    },
  ];

  const imgUrl = "img/services/imagemsos.png";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="About Us" subtitle="About" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />

      <HomeThreeFaq />

      {/* <HomeOurTeam /> */}
      {/* <AboutTestimonial /> */}
      {/* <AboutAnalysis /> */}

      <HomeFooter />
    </>
  );
};

export default SOSGinecologistaOnline;
