import React from "react";

const ModalSpinner = ({ text }) => {
  return (
    <div className="loading-overlay">
      {text && <div className="loading-text">{text}</div>}
      <div className="loading-spinner"></div>
    </div>
  );
};

export default ModalSpinner;
