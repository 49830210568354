import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import TermsOfUse from "../Blogs/BlogsArea/TermosGerais";
import HomeHeroSection from "../Home/Home/HomeHeroSection/HomeHeroSection";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";

const TermosGerais = () => {
  return (
    <>
      <HomeHeader />
      <HomeHeroSection />
      <TermsOfUse />
      <HomeFooter />
    </>
  );
};

export default TermosGerais;
