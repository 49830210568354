import React from "react";
import "./styles.css";

const Ginecologia = () => {
  localStorage.setItem("page", "Ginecologia");

  const options = [
    { href: "/anticoncepcao", text: "Anticoncepção" },
    {
      href: "/insercaoTrocaDIUsImplanon",
      text: "Inserção / Troca DIUs e Implanon",
    },
    { href: "/primeiraConsulta", text: "Primeira consulta ginecológica" },
    { href: "/rotinaGinecologica", text: "Rotina ginecológica" },
    { href: "/sosGinecologistaOnline", text: "S.O.S Ginecologista" },
    { href: "/avaliacaoFertilidade", text: "Avaliação da fertilidade" },
    {
      href: "/investigacaoInfertilidade",
      text: "Investigação da infertilidade",
    },
    { href: "/reposicaoHormonal", text: "Reposição hormonal na menopausa" },
  ];

  return (
    <section
      id="ginecologia"
      className="about-area pt-130 pb-100"
      style={{ backgroundColor: "#D5C5E7" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12" style={{ textAlign: "center" }}>
            <h2>Veja como podemos te ajudar</h2>
          </div>

          <div className="col-lg-6 order-2 order-lg-1">
            <div className="h5medical-content mb-30">
              <h3 className="f-600 theme-colo mt-60">Ginecologia</h3>
              <div className="mobile-only">
                <img src="img/ginecologia.png" alt="Ginecologia" />
              </div>
              <p className="mt-30" style={{ color: "#111111" }}>
                Com consultas virtuais e suporte personalizado, estamos aqui
                para simplificar sua jornada de cuidados femininos. Libere mais
                tempo para você enquanto cuidamos da sua saúde. Comece agora
                mesmo!
              </p>
              <ul className="options-list">
                {options.map((option, index) => (
                  <li key={index} className="option-item">
                    <a className="option-link" href={option.href}>
                      <i
                        style={{ color: "#fff" }}
                        className="far fa-arrow-alt-circle-right"
                      ></i>
                      {option.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div
            className="col-lg-6 order-1 order-lg-2 desktop-only"
            style={{ margin: "auto" }}
          >
            <div className="h5medical__thumb mb-30">
              <img
                src="img/ginecologia-2.png"
                className="desktop-only-image"
                alt="Ginecologia"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ginecologia;
