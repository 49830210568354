import React from "react";

const CancellationPolicy = () => {
  return (
    <div
      className="container"
      style={{ marginBottom: "150px", marginTop: "100px" }}
    >
      <div className="row">
        <div className="col-12">
          <h1>Política de Cancelamento e Reagendamento de Consultas</h1>
          <h3>Procedimento para Cancelamento ou Reagendamento</h3>
          <p>
            É responsabilidade do cliente verificar e confirmar o agendamento da
            consulta. Alterações ou cancelamentos devem ser comunicados dentro
            do prazo estabelecido para evitar custos adicionais. Tanto o
            cancelamento quanto o reagendamento devem ser comunicados e
            solicitados através da plataforma online, conforme as regras abaixo
            estabelecidas:
          </p>

          <h2>1. Cancelamento de Consultas:</h2>
          <ul>
            <li>
              As consultas poderão ser canceladas, sem ônus, em até 24hrs antes
              do horário agendado, com reembolso integral do valor pago ou
              crédito para futuros agendamentos. Caso o cancelamento não seja
              comunicado no referido prazo, será considerado prestado o serviço
              e não haverá devolução do valor pago.
            </li>
          </ul>

          <h2>2. Reagendamento de Consultas:</h2>
          <ul>
            <li>
              Os reagendamentos podem comunicados até 3 (três) horas antes do
              horário agendado, sem custo adicional, sujeito à disponibilidade
              de horários do paciente/cliente e do profissional responsável.
            </li>
          </ul>

          <h2>3. Contato e Suporte:</h2>
          <p>
            Para quaisquer dúvidas ou informações adicionais sobre nossa
            política de cancelamento e reagendamento, entre em contato conosco
            através do email:
            <a href="mailto:contato@mulherolis.com">contato@mulherolis.com</a>.
          </p>
          <p>
            Obs. Em caso de dificuldades técnicas, ou outras questões, o
            paciente/cliente pode entrar em contato diretamente com nosso
          </p>

          <h2>Observação:</h2>
          <p>
            Esta política foi elaborada de acordo com as diretrizes do Código de
            Defesa do Consumidor e está sujeita a alterações sem aviso prévio.
            Qua isquer mudanças serão comunicadas aos clientes através de nossos
            canais de comunicação.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CancellationPolicy;
