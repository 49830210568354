import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios"; // Certifique-se de ter o axios instalado para realizar a requisição

const FullWidthInput = styled.input`
  width: 100%;
  margin-top: 10px;
`;

const CenteredButton = styled.button`
  width: 100%;
  margin-top: 10px;
`;

const Checkout = () => {
  const paymentMethod = localStorage.getItem("paymentMethod");
  const dados = JSON.parse(localStorage.getItem("resultCompra"));

  const [image, setImage] = useState(null);
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);

  useEffect(() => {
    if (paymentMethod === "PIX") {
      const { payload, encodedImage } = dados.qrCode;
      setImage(`data:image/png;base64,${encodedImage}`);
    }

    // Função para verificar o status de confirmação do pagamento
    const verificarPagamento = async () => {
      const idCobranca = dados.response.id;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_API}/transacoes/${idCobranca}`
        );
        const paymentStatus = response.data;
        let statusPagamento = paymentStatus[0].status;

        if (statusPagamento === "CONFIRMED") {
          setIsPaymentConfirmed(true);
          return true; // Retornar true para indicar que a transação foi confirmada
        }

        return false; // Retornar false se ainda não foi confirmada
      } catch (error) {
        console.error("Erro ao verificar pagamento:", error);
        return false;
      }
    };

    // Função para verificar periodicamente
    if (paymentMethod === "PIX") {
      const intervalo = setInterval(async () => {
        const confirmado = await verificarPagamento();
        if (confirmado) {
          clearInterval(intervalo); // Para de verificar caso seja confirmado
        }
      }, 5000); // Verifica a cada 5 segundos

      return () => clearInterval(intervalo); // Limpa o intervalo quando o componente for desmontado
    }
  }, [paymentMethod, dados]);

  const handleCopiarPix = () => {
    navigator.clipboard.writeText(dados.qrCode.payload);
    Swal.fire({
      icon: "success",
      title: "Código PIX copiado",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <section className="about-area pt-130 pb-100">
      <div className="container">
        <div className="row justify-content-center">
          {isPaymentConfirmed ? (
            <div className="col-xl-6 col-lg-6 text-center">
              <img
                src="/img/success.svg"
                alt="Pagamento Confirmado"
                className="img-fluid"
              />
              <h2>Pagamento Confirmado com Sucesso!</h2>
              <p>
                Agora é só aguardar o envio do seu agendamento tanto no Whatsapp
                quanto no seu e-mail.
              </p>
            </div>
          ) : (
            <>
              <div className="col-xl-6 col-lg-6">
                <div className="h4about-thumb pos-rel text-center">
                  <img src={image} alt="QR Code PIX" className="img-fluid" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="about-right-side h4about-right mb-30">
                  <div className="about-title mb-20 text-center">
                    <h5 className="pink-color">
                      Prontinho! Vamos pra última parte
                    </h5>
                    <h1>Finalize seu pagamento</h1>
                  </div>
                  <div className="about-text text-center">
                    <p className="theme-color">
                      Passo a passo PIX:
                      <br />
                      1. Abra o aplicativo do seu banco
                      <br />
                      2. Selecione a opção PIX
                      <br />
                      3. Selecione a opção Pagar
                      <br />
                      4. Escaneie o QR Code
                      <br />
                      5. Confirme o pagamento
                      <br />
                      6. Pronto! Agora é só esperar a confirmação do pagamento
                    </p>

                    <TextField
                      fullWidth
                      label="Pix copia e cola"
                      value={dados?.qrCode?.payload}
                      disabled
                      name="pixcc"
                      margin="normal"
                    />

                    <CenteredButton
                      onClick={handleCopiarPix}
                      className="primary_btn btn-icon ml-0"
                    >
                      Copiar o código PIX
                    </CenteredButton>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Checkout;
