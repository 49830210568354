import React, { useEffect, useState } from "react";
import fetch from "node-fetch";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import ModalSpinner from "./ModalSpinner";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom"; // Para acessar o idConsulta da URL

const horarios = [
  {
    id: 0,
    name: "Selecione um horário",
    status: "available",
  },
  {
    id: 1,
    name: "08:00",
    status: "available",
  },
  {
    id: 2,
    name: "08:30",
    status: "available",
  },
  {
    id: 3,
    name: "09:00",
    status: "available",
  },
  {
    id: 4,
    name: "09:30",
    status: "available",
  },
  {
    id: 5,
    name: "10:00",
    status: "available",
  },
  {
    id: 6,
    name: "10:30",
    status: "available",
  },
  {
    id: 7,
    name: "11:00",
    status: "available",
  },
  {
    id: 8,
    name: "11:30",
    status: "available",
  },
  {
    id: 9,
    name: "12:00",
    status: "available",
  },
  {
    id: 10,
    name: "12:30",
    status: "available",
  },
  {
    id: 11,
    name: "13:00",
    status: "available",
  },
  {
    id: 12,
    name: "13:30",
    status: "available",
  },
  {
    id: 13,
    name: "14:00",
    status: "available",
  },
  {
    id: 14,
    name: "14:30",
    status: "available",
  },
  {
    id: 15,
    name: "15:00",
    status: "available",
  },
  {
    id: 16,
    name: "15:30",
    status: "available",
  },
  {
    id: 17,
    name: "16:00",
    status: "available",
  },
  {
    id: 18,
    name: "16:30",
    status: "available",
  },
  {
    id: 19,
    name: "17:00",
    status: "available",
  },
  {
    id: 20,
    name: "17:30",
    status: "available",
  },
];
const BookAppoinment = ({ idArea, idServico }) => {
  const [areaSelecionada, setAreaSelecionada] = useState("");
  const [serviceSelecionado, setServiceSelecionado] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [cpf, setCpf] = useState("");
  const [horario, setHorario] = useState("");
  const [data, setData] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  const { idConsulta } = useParams(); // Pega o idConsulta da URL

  // Novos estados para o endereço
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [numero, setNumero] = useState("");

  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [ccv, setCcv] = useState("");
  const [installmentCount, setInstallmentCount] = useState(1);

  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);
  const [servico, setServico] = useState(null); // Estado para armazenar o resultado da API

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  useEffect(() => {
    setAreaSelecionada(idArea);
    setServiceSelecionado(idServico);
  }, [idArea, idServico]);

  useEffect(() => {
    if (areaSelecionada && serviceSelecionado) {
      fetchServico(areaSelecionada, serviceSelecionado);
    }
  }, [areaSelecionada, serviceSelecionado]);

  const fetchServico = async (areaId, servicoId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_API}/subservicosPorId/${servicoId}`
      );
      const data = await response.json();
      console.log("Serviço buscado:", data);
      if (response.ok) {
        setServico(data); // Armazena o resultado na constante servico
      } else {
        console.error("Erro ao buscar o serviço:", data.message);
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  const alerta = (titulo, texto, icone) => {
    Swal.fire({
      title: titulo,
      text: texto,
      icon: icone,
      showCancelButton: false,
      confirmButtonColor: "#ff5e15",
      confirmButtonText: "Ok",
    });
  };

  const submitForm = async (event) => {
    if (event) event.preventDefault(); // Impede o comportamento padrão

    setLoading(true);

    if (!horario) {
      alerta("Campo obrigatório", "Por favor, selecione um horário", "error");
      setLoading(false);
      setStep(1);
      return; // Adiciona retorno para impedir continuação
    }

    if (!data) {
      alerta("Campo obrigatório", "Por favor, selecione uma data", "error");
      setLoading(false);
      setStep(1);
      return; // Adiciona retorno para impedir continuação
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_API}/consulta_atualizar/${idConsulta}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idConsulta: idConsulta,
            date: data,
            time: horario,
          }),
        }
      );

      const result = await response.json();

      console.log("Success:", result);

      if (result.error) {
        Swal.fire({
          icon: "error",
          title: "Não foi possível agendar a consulta",
          text: result.descricaoErro,
        });
        setLoading(false);
        return; // Retorna para evitar continuação
      }

      setLoading(false);
      Swal.fire({
        title: "Consulta agendada com sucesso!",
        html: "Em breve receberá mais informações.",
        imageUrl: "../img/success.png",
        imageWidth: "400px",
        imageHeight: "400px",
        confirmButtonText: "Ok",
      });
    } catch (error) {
      console.error("Error:", error);
      alerta("Erro", "Erro ao agendar a consulta", "error");
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <ModalSpinner text={"Finalizando seu pagamento"} />}

      <section
        className="appointment-area appointment-area-3 appointment_page_bg pos-rel pt-115 pb-120"
        data-background="img/slider/capa.png"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="calculate-box white-bg">
                <div className="calculate-content">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="about-title news-letter-title mb-70">
                        <h5 className="pink-color">
                          Marque sua remarcação/retorno aqui
                        </h5>
                        <h1>Bem vinda novamente, mulher Olis</h1>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {step === 1 && (
                      <StepOne
                        key={"stepOne"}
                        data={data}
                        setData={setData}
                        horario={horario}
                        setHorario={setHorario}
                        horarios={horarios}
                        nextStep={nextStep}
                      />
                    )}

                    {step === 2 && (
                      <StepFour
                        key={"stepFour"}
                        data={data}
                        horario={horario}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        submitForm={submitForm}
                        prevStep={prevStep}
                        cardName={cardName}
                        setCardName={setCardName}
                        cardNumber={cardNumber}
                        setCardNumber={setCardNumber}
                        expiryDate={expiryDate}
                        setExpiryDate={setExpiryDate}
                        ccv={ccv}
                        setCcv={setCcv}
                        servico={servico}
                        installmentCount={installmentCount}
                        setInstallmentCount={setInstallmentCount}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookAppoinment;
