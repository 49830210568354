import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollTop from "./components/ScrollTop";
import AllContext from "./context/AllContext";
import AboutUs from "./pages/AboutUs/AboutUs";
import Appointment from "./pages/Appointment/Appointment";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import BlogLeftSideBar from "./pages/BlogLeftSideBar/BlogLeftSideBar";
import BlogNoSideBar from "./pages/BlogNoSideBar/BlogNoSideBar";
import Blogs from "./pages/Blogs/Blogs";
import BlogThreeColMasonry from "./pages/BlogThreeColMasonry/BlogThreeColMasonry";
import BlogThreeColumn from "./pages/BlogThreeColumn/BlogThreeColumn";
import BlogTwoColumn from "./pages/BlogTwoColumn/BlogTwoColumn";
import BlogTwoColumnMasonry from "./pages/BlogTwoColumnMasonry/BlogTwoColumnMasonry";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import Contact from "./pages/Contact/Contact";
import DetailsAudio from "./pages/DetailsAudio/DetailsAudio";
import DetailsGallery from "./pages/DetailsGallery/DetailsGallery";
import DetailsLeftSideBar from "./pages/DetailsLeftSideBar/DetailsLeftSideBar";
import DetailsVideo from "./pages/DetailsVideo/DetailsVideo";
import DoctorDetails from "./pages/Doctors/DoctorDetails/DoctorDetails";
import DoctorsOne from "./pages/Doctors/DoctorsOne/DoctorsOne";
import DoctorsTwo from "./pages/Doctors/DoctorsTwo/DoctorsTwo";
import Home from "./pages/Home/Home/Home";
import HomeThree from "./pages/HomeThree/HomeThree";
import HomeTwo from "./pages/HomeTwo/HomeTwo";
import HomeFour from "./pages/HomeFour/HomeFour";
import Login from "./pages/Login/Login";
import PortfolioSlider from "./pages/PortfolioSlider/PortfolioSlider";
import PortfolioThreeCol from "./pages/PortfolioThreeCol/PortfolioThreeCol";
import PortfolioTwoColumn from "./pages/PortfolioTwoColumn/PortfolioTwoColumn";
import Register from "./pages/Register/Register";
import ServicesDetails from "./pages/Services/ServicesDetails/ServicesDetails";
import Obstetricia from "./pages/Services/Obstetricia/Obstetricia";
import Ginecologia from "./pages/Services/Ginecologia/Ginecologia";
import Remarcacao from "./pages/Remarcacao/Remarcacao";
// import ServicesTwo from "./pages/Services/ServicesTwo/ServicesTwo";
import ShopDetails from "./pages/ShopDetails/ShopDetails/ShopDetails";
import ShopPage from "./pages/ShopPage/ShopPage/ShopPage";
import ShoppingCart from "./pages/ShoppingCart/ShoppingCart";
import WishList from "./pages/wishList/WishList";
import HomeFive from "./pages/HomeFive/HomeFive";
import "./App.css";
import NotFound from "./pages/NotFound/NotFound";
// import PrimeiraConsultas from "./pages/Services/ServicosOlis/primeiraConsulta";
import PrimeiraConsulta from "./pages/ServicosOlis/primeiraConsulta";
import RotinaGinecologica from "./pages/ServicosOlis/rotinaGinecologica";
import Anticoncepcao from "./pages/ServicosOlis/anticoncepcao";
import AvaliacaoFertilidade from "./pages/ServicosOlis/avaliacaofertilidade";
import ReposicaoHormonal from "./pages/ServicosOlis/reposicaoHormonal";
import InvestigacaoInfertilidade from "./pages/ServicosOlis/investigacaoInfertilidade";
import Finalizar from "./pages/Finalizar/Home/Finalizar";

import ConsultoriaPreNatal from "./pages/ServicosOlis/preNatalADistancia";
import SuplementacaoGestacao from "./pages/ServicosOlis/suplementacaoGestacao";
import SuplementacaoPosParto from "./pages/ServicosOlis/suplementacaoPosParto";
import DiabetesGestacional from "./pages/ServicosOlis/diabetesGestacional";
import InsercaoTrocaDIUsImplanon from "./pages/ServicosOlis/insercaoDIU";
import SOSGinecologistaOnline from "./pages/ServicosOlis/sosGinecologista";
import SuplementacaoPreGestacional from "./pages/ServicosOlis/suplementaoPreGestacional";
import PoliticaPrivacidade from "./pages/BlogNoSideBar/PoliticaPrivacidade";
import PoliticaCancelamento from "./pages/BlogNoSideBar/PoliticaCancelamento";
import TermoConsentimento from "./pages/BlogNoSideBar/TermoConsentimento";
import TermosGerais from "./pages/BlogNoSideBar/TermosGerais";

// import ConsultoriaDiabetesGestacional from "./pages/ServicosOlis/consultoriaDiabetesGestacional";

function App() {
  return (
    <>
      <AllContext>
        <BrowserRouter>
          <ScrollTop />
          <Routes>
            <Route
              path="/politicaPrivacidade"
              element={<PoliticaPrivacidade />}
            />
            <Route
              path="/politicaCancelamento"
              element={<PoliticaCancelamento />}
            />
            <Route
              path="/termoConsentimento"
              element={<TermoConsentimento />}
            />
            <Route path="/termosGerais" element={<TermosGerais />} />

            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/homeTwo" element={<HomeTwo />} />
            <Route path="/homeThree" element={<HomeThree />} />
            <Route path="/homeFour" element={<HomeFour />} />
            <Route path="/homeFive" element={<HomeFive />} />
            <Route path="/ginecologia" element={<Ginecologia />} />
            <Route path="/obstetricia" element={<Obstetricia />} />
            <Route path="/primeiraConsulta" element={<PrimeiraConsulta />} />
            <Route
              path="/rotinaGinecologica"
              element={<RotinaGinecologica />}
            />
            <Route path="/anticoncepcao" element={<Anticoncepcao />} />
            <Route
              path="/investigacaoInfertilidade"
              element={<InvestigacaoInfertilidade />}
            />
            <Route path="/reposicaoHormonal" element={<ReposicaoHormonal />} />
            <Route
              path="/avaliacaoFertilidade"
              element={<AvaliacaoFertilidade />}
            />
            <Route
              path="/consultoriaPreNatal"
              element={<ConsultoriaPreNatal />}
            />

            <Route
              path="/suplementacaoNaGestacao"
              element={<SuplementacaoGestacao />}
            />

            <Route
              path="/suplementacaoPosParto"
              element={<SuplementacaoPosParto />}
            />

            <Route
              path="/diabetesGestacional"
              element={<DiabetesGestacional />}
            />

            <Route
              path="/insercaoTrocaDIUsImplanon"
              element={<InsercaoTrocaDIUsImplanon />}
            />

            <Route
              path="/sosGinecologistaOnline"
              element={<SOSGinecologistaOnline />}
            />

            <Route
              path="/suplementacaoPreGestacional"
              element={<SuplementacaoPreGestacional />}
            />

            <Route path="/finalizar" element={<Finalizar />} />

            {/* <Route path="/servicesTwo" element={<ServicesTwo />} /> */}
            <Route path="/servicesDetails" element={<ServicesDetails />} />
            <Route path="/doctors" element={<DoctorsOne />} />
            <Route path="/doctorsTwo" element={<DoctorsTwo />} />
            <Route path="/doctorDetails" element={<DoctorDetails />} />
            <Route path="/shop" element={<ShopPage />} />
            <Route path="/shopDetails" element={<ShopDetails />} />
            <Route path="/shoppingCart" element={<ShoppingCart />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/wishList" element={<WishList />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogLeftSideBar" element={<BlogLeftSideBar />} />
            <Route path="/blogNoSideBar" element={<BlogNoSideBar />} />
            <Route path="/blogTwoColumn" element={<BlogTwoColumn />} />
            <Route path="/blogTwoMasonry" element={<BlogTwoColumnMasonry />} />
            <Route path="/blogThreeColumn" element={<BlogThreeColumn />} />
            <Route
              path="/blogThreeColMasonry"
              element={<BlogThreeColMasonry />}
            />
            <Route path="/blogDetails" element={<BlogDetails />} />
            <Route
              path="/blogDetailsLeftSidebar"
              element={<DetailsLeftSideBar />}
            />
            <Route path="/blogDetailsAudio" element={<DetailsAudio />} />
            <Route path="/blogDetailsVideo" element={<DetailsVideo />} />
            <Route path="/blogDetailsGallery" element={<DetailsGallery />} />
            <Route path="/about" element={<AboutUs />} />
            <Route
              path="/agendamento/:idArea/:idServico"
              element={<Appointment />}
            />
            <Route path="/remarcacao/:idConsulta" element={<Remarcacao />} />
            <Route path="/agendamento" element={<Appointment />} />

            <Route
              path="/portfolioTwoColumn"
              element={<PortfolioTwoColumn />}
            />
            <Route
              path="/portfolioThreeColumn"
              element={<PortfolioThreeCol />}
            />
            <Route path="/portfolioSlider" element={<PortfolioSlider />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/notMatch" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AllContext>
    </>
  );
}

export default App;
