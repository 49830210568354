import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalConsulta from "../Modal";
const ServicesOneHiringArea = () => {
  const [isOpen, setIsOpen] = useState(false);

  const chamarModal = () => {
    console.log("chamou");
    setIsOpen(true);
    console.log(isOpen);
  };

  return (
    <>
      <ModalConsulta isOpen={isOpen}></ModalConsulta>
      <section className="hiring-area pt-120 pb-120">
        <div className="container">
          <div className="row no-gutters hire-bg-2">
            <div className="col-xl-6 col-lg-6">
              <div className="hire-img">
                <img className="img" src="img/hire/hire1.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="hire-text">
                <h1>Primeira Consulta Ginecológica</h1>
                <p>
                  A primeira consulta ginecológica é voltada para as mulheres
                  Olis adolescentes a partir da primeira menstruação. <br />{" "}
                  Aqui vamos ter um papo sobre o funcionamento do organismo
                  feminino, as mudanças corporais, os cuidados de higiene,
                  funcionamento do ciclo menstrual, os principais sintomas
                  relacionados à menstruação, como a TPM, sexo seguro e tirar
                  suas dúvidas. Entendemos que nesse momento a informação será o
                  mais importante. <br /> Vamos conversar também sobre seu
                  histórico de saúde, prevenção de doenças, e sobre a
                  importância da atividade física e uma alimentação equilibrada.{" "}
                  <br /> Essa consulta poderá ser realizada individualmente ou
                  na presença de um acompanhante. Respeitamos o seu desejo e
                  queremos que você se sinta confortável e a vontade. <br />{" "}
                  Valor da consulta - 359, 99 ou 6 x 69,99
                  <br />{" "}
                  <b onClick={chamarModal}>Saiba mais sobre a consulta aqui</b>
                </p>
                <Link
                  to="/agendamento"
                  className="primary_btn btn-icon btn-icon-green ml-0"
                  onClick={() => {
                    localStorage.setItem(
                      "service",
                      "Consultoria pré-natal à distância"
                    );
                  }}
                >
                  <span>+</span>Agende agora
                </Link>
              </div>
            </div>
          </div>

          <div className="row no-gutters hire-bg">
            <div className="col-xl-6 col-lg-6">
              <div className="hire-text">
                <h1>Suplementação preconcepcional, gestacional e pós-parto</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <Link
                  to="/agendamento"
                  className="primary_btn btn-icon ml-0"
                  onClick={() => {
                    localStorage.setItem(
                      "service",
                      "Suplementação preconcepcional, gestacional e pós-parto"
                    );
                  }}
                >
                  <span>+</span>Agende agora
                </Link>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="hire-img">
                <img className="img" src="img/hire/hire2.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="row no-gutters hire-bg-2">
            <div className="col-xl-6 col-lg-6">
              <div className="hire-img">
                <img className="img" src="img/hire/hire1.jpg" alt="" />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div className="hire-text">
                <h1> Consultoria - Diabetes Gestacional</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <Link
                  to="/agendamento"
                  className="primary_btn btn-icon btn-icon-green ml-0"
                  onClick={() => {
                    localStorage.setItem(
                      "service",
                      "Consultoria - Diabetes Gestacional"
                    );
                  }}
                >
                  <span>+</span>Agende agora
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesOneHiringArea;
