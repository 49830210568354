import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import HomeOurTeam from "../Home/Home/HomeOurTeam/HomeOurTeam";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const RotinaGinecologica = () => {
  const title = "Rotina Ginecológica";
  const descricao =
    "Nunca foi tão simples deixar a saúde em dia mesmo com a correria cotidiana. Para Olis antenadas que entendem a sua saúde como alicerce que sustenta todas as suas conquistas.";
  const price = "R$ 359,99 ou até 6x de R$ 69,99";

  const fatos = {
    titulo_fatos: "Sinta-se ouvida",
    descricao_fatos:
      "Você precisa ser ouvida, compreendida e respeitada. Nessa consulta vamos conhecer mais sobre você e entender suas necessidades. Assim, definiremos quais as medidas necessárias para prevenção de doenças, como exames e orientações para melhora da qualidade de vida",
    subtitulos: [
      {
        titulo: "Não perca tempo",
        subtitulo:
          "Sem precisar sair de casa ou desmarcar compromissos, você tem acesso a profissionais que irão te ajudar na prevenção de doenças e melhorar sua qualidade de vida!",
      },
      {
        titulo: "Cuidado Personalizado",
        subtitulo:
          "Para que nada fique esquecido, te enviaremos um relatório com todas as informações e condutas definidas na nossa consulta. Aproveite esse presente!",
      },
    ],
  };

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Consulta realizada por videochamada (~ 30 min)",
      descricao: "Converse com a gente",
    },
    {
      titulo:
        "Solicitação de exames e envio de prescrição médica, se necessário",
      descricao: "Converse com a gente",
    },

    {
      titulo:
        "Envio de exames prévios via link recebido pelo WhatsApp, se houverem",
      descricao: "Realize os exames",
    },
    {
      titulo: "Retorno em até 30 dias para ver seus exames.",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Envio de Relatório Médico",
      descricao: "Receba o relatório médico",
    },
  ];

  const idArea = 1;
  const idServico = 2;

  const imgUrl = "img/ginecologia.png";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="About Us" subtitle="About" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />{" "}
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}
      <HomeThreeFaq />
      <HomeFooter />
    </>
  );
};

export default RotinaGinecologica;
