import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import InformedConsent from "../Blogs/BlogsArea/TermoConsentimento";
import HomeHeroSection from "../Home/Home/HomeHeroSection/HomeHeroSection";

const TermoConsentimento = () => {
  return (
    <>
      <HomeHeader />
      <HomeHeroSection />
      <InformedConsent />
    </>
  );
};

export default TermoConsentimento;
