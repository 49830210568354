import React, { useState } from "react";
import CommonSectionArea from "../../../components/CommonSectionArea/CommonSectionArea";
import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";

const AboutArea = ({
  title,
  descricao,
  price,
  imgUrl,
  idArea,
  idServico,
  pacotes,
}) => {
  const [isOpen, setOpen] = useState(false);

  imgUrl = imgUrl || "img/woman.png";

  return (
    <>
      <CommonSectionArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        pacotes={pacotes}
        idServico={idServico}
        area_header={
          <div className="about-left-side pos-rel mb-30">
            <div className="about-front-img pos-rel">
              <img src={imgUrl} alt="" />
            </div>
            <div className="about-shape">
              <img src="img/about/about-shape.png" alt="" />
            </div>
          </div>
        }
      />

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="I3u3lFA9GX4"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default AboutArea;
