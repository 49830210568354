import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import HomeOurTeam from "../Home/Home/HomeOurTeam/HomeOurTeam";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const ReposicaoHormonal = () => {
  const title = "Reposição Hormonal na Menopausa";
  const descricao =
    "Entendemos que o climatério e menopausa são fases que  podem trazer desafios únicos e sintomas desconfortáveis. </br>Estamos aqui para oferecer apoio e soluções personalizadas para ajudá-la a enfrentar essa transição com mais conforto e qualidade de vida";
  const price = "R$ 359,00 ou até 6x de R$ 69,90";

  const fatos = {
    titulo_fatos: "Priorize Seu Bem-Estar",
    descricao_fatos:
      "A menopausa é uma nova fase da vida que pode ser vivida com saúde e bem-estar. Com nosso serviço de cuidados com climatério e menopausa, você encontrará o suporte necessário para atravessar essa transição com confiança e serenidade.",
    subtitulos: [
      {
        titulo: "Cuidado integral",
        subtitulo:
          "Garantimos uma abordagem cuidadosa e individualizada para cada mulher Olis . Com o tratamento de reposição hormonal, você pode experimentar alívio dos sintomas como ondas de calor, suores noturnos, alterações de humor, ressecamento vaginal , problemas relacionados ao sono, além da prevenção de doenças, como osteoporose.",
      },
      {
        titulo: "Cuidado individualizado",
        subtitulo:
          "Se a reposição hormonal for considerada contraindicada para você, ofereceremos alternativas para ajudar a aliviar seus sintomas e melhorar sua qualidade de vida. Trabalharemos juntos para encontrar o melhor plano de tratamento adaptado às suas necessidades e circunstâncias individuais.",
      },
    ],
  };

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo:
        "Envio de exames prévios para nosso link de whatsapp ,se houverem",
      descricao: "Receba o link de atendimento",
    },

    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Consulta realizada por videochamada ( ~ 45 min)",
      descricao: "Aguarde o retorno",
    },
    {
      titulo:
        "Envio de exames prévios via link recebido pelo WhatsApp, se houverem",
      descricao: "Realize os exames",
    },
    {
      titulo: "Retorno em até 30 dias para ver seus exames.",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Envio de relatório Médico",
      descricao: "Receba o relatório médico",
    },
  ];

  const idArea = 1;
  const idServico = 12;

  const imgUrl = "img/menopausa.png";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="About Us" subtitle="About" /> */}
      <AboutArea
        imgUrl={imgUrl}
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
      />{" "}
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}
      <HomeThreeFaq />
      <HomeFooter />
    </>
  );
};

export default ReposicaoHormonal;
