import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import HomeHeroSection from "../Home/Home/HomeHeroSection/HomeHeroSection";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";

import CancellationPolicy from "../Blogs/BlogsArea/PoliticaCancelamento";
const PoliticaCancelamento = () => {
  return (
    <>
      <HomeHeader />
      <HomeHeroSection />
      <CancellationPolicy />
      <HomeFooter />
    </>
  );
};

export default PoliticaCancelamento;
