import React from "react";
import { Link } from "react-router-dom";
import HomeSingleTeam from "../../../../components/HomeSingleTeam/HomeSingleTeam";

const HomeOurTeam = () => {
  const team = [
    {
      image: "1",
      title: "Ana Carolina Almeida",
      subtitle: "Ginecologista e Obstetra",
      crm: "CRM 196022",
      rqe: "RQE 120938",
    },
    {
      image: "2",
      title: "Hellen Geyer",
      subtitle: "Ginecologista e Obstetra",
      crm: "CRM 213503",
      rqe: "RQE 116271",
    },
    {
      image: "3",
      title: "Paula Vieira",
      subtitle: "Ginecologista e Obstetra",
      crm: "CRM 197361",
      rqe: "RQE 104318",
    },
  ];

  return (
    <>
      <section className="team-area pt-115 pb-55">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-10">
              <div className="section-title pos-rel mb-75">
                <div className="section-icon">
                  {/* <img
                    className="section-back-icon back-icon-left"
                    src="img/section/section-back-icon.png"
                    alt=""
                  /> */}
                </div>
                <div className="section-text pos-rel">
                  {/* <h5>Nosso time</h5> */}
                  <h1>Quem cuidará de você</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {team.map((team, index) => (
              <HomeSingleTeam
                key={index}
                image={team.image}
                title={team.title}
                subtitle={team.subtitle}
                crm={team.crm}
                rqe={team.rqe}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeOurTeam;
