import React from "react";
import { Link } from "react-router-dom";

const AboutAppoinment = ({ passos }) => {
  return (
    <>
      <section
        className="appoinment-section pt-120 pb-120"
        data-background="img/slider/capa.png"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="appoinment-box white">
                <div className="appoinment-content">
                  {/* <span className="small-text">Como funciona</span> */}
                  <h1>Passo a passo para sua consulta</h1>
                  <p>Descubra como é simples se consultar com a Olis</p>

                  <ul className="professinals-list pt-30">
                    {passos.map((passo, index) => (
                      <li key={index}>
                        <div className="single-professinals">
                          <div className="professinals-icon">
                            <i className="fa fa-check"></i>
                          </div>
                          <div className="professinals-text">
                            <h4>{passo.titulo}</h4>
                            {/* <p>{passo.descricao}</p> */}
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <Link to="/contact" className="primary_btn mt-40">
                  Agendar agora
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutAppoinment;
