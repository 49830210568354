import React from "react";
import "./styles.css";

const Obstetricia = () => {
  localStorage.setItem("page", "Obstetricia");

  const options = [
    { href: "/consultoriaPreNatal", text: "Pré-Natal à Distância" },
    {
      href: "/suplementacaoPreGestacional",
      text: "Suplementação Pré Gestacional",
    },
    {
      href: "/suplementacaoNaGestacao",
      text: "Suplementação Na Gestação",
    },
    { href: "/suplementacaoPosParto", text: "Suplementação Pós Parto" },
    {
      href: "/diabetesGestacional",
      text: "Consultoria em Diabetes Gestacional",
    },
  ];

  return (
    <>
      <section
        id="obstetricia"
        className="about-area pt-130 pb-100"
        style={{ backgroundColor: "#f4f4f4" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 order-1 order-lg-1 desktop-only"
              style={{ margin: "auto" }}
            >
              <div className="h5medical__thumb mb-30">
                <img src="img/obstetricia.png" alt="Obstetricia" />
              </div>
            </div>

            <div className="col-lg-6 order-2 order-lg-2">
              <div className="h5medical-content mb-30">
                <h3 className="f-600 theme-color mt-30">Obstetrícia</h3>
                <div className="mobile-only">
                  <img src="img/obstetricia.png" alt="Obstetricia" />
                </div>
                <p className="mt-30" style={{ color: "#111111" }}>
                  Oferecemos serviços obstétricos personalizados, permitindo que
                  você acompanhe sua gravidez com tranquilidade, mesmo com uma
                  agenda agitada. Suporte especializado para apoiá-la em cada
                  etapa dessa jornada especial. Cuide da sua saúde e do seu bebê
                  de forma eficaz e sem estresse.
                </p>
                <ul>
                  {options.map((option, index) => (
                    <li key={index} className="option-item">
                      <a className="option-link" href={option.href}>
                        <i
                          style={{ color: "#fff" }}
                          className="far fa-arrow-alt-circle-right"
                        ></i>
                        {option.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div
              className="col-lg-6 order-1 order-lg-2 desktop-only"
              style={{ margin: "auto" }}
            ></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Obstetricia;
