import React from "react";
import { Link } from "react-router-dom";
import useGlobalContext from "../../hooks/useGlobalContext";
import styled from "styled-components";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparente */
  z-index: 1;
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  color: #d767b4;
`;

const HomeHeroSingleSlide = ({ bg_className }) => {
  const videoPopup = useGlobalContext();
  const { setIsOpen } = videoPopup;
  return (
    <>
      <div
        className={`single-slider slider-height d-flex align-items-center slider_bg_${bg_className}`}
        style={{ position: "relative" }}
      >
        <Overlay />
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <ContentContainer className="hero-text">
                <div className="hero-slider-caption ">
                  <h2 style={{ color: `#f8f9fa` }}>Mulheres que entendem.</h2>
                  <h1 style={{ color: `#f8f9fa` }}>Olá, Mulher Olis.</h1>
                  <h5 style={{ color: `#f8f9fa` }}>
                    Sua Jornada de Saúde Feminina Começa Aqui <br />
                    Conectamos você ao que há de mais moderno no cuidado com sua
                    saúde
                  </h5>
                </div>
                <div className="hero-slider-btn mt-4">
                  <a href="#ginecologia" className="primary_btn btn-icon ml-0">
                    <span>+</span>Conheça a Olis
                  </a>
                </div>
              </ContentContainer>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHeroSingleSlide;
