import React, { useEffect, useState } from "react";
import fetch from "node-fetch";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
import ModalSpinner from "./ModalSpinner";
import Swal from "sweetalert2";

const horarios = [
  {
    id: 0,
    name: "Selecione um horário",
    status: "available",
  },
  {
    id: 1,
    name: "08:00",
    status: "available",
  },
  {
    id: 2,
    name: "08:30",
    status: "available",
  },
  {
    id: 3,
    name: "09:00",
    status: "available",
  },
  {
    id: 4,
    name: "09:30",
    status: "available",
  },
  {
    id: 5,
    name: "10:00",
    status: "available",
  },
  {
    id: 6,
    name: "10:30",
    status: "available",
  },
  {
    id: 7,
    name: "11:00",
    status: "available",
  },
  {
    id: 8,
    name: "11:30",
    status: "available",
  },
  {
    id: 9,
    name: "12:00",
    status: "available",
  },
  {
    id: 10,
    name: "12:30",
    status: "available",
  },
  {
    id: 11,
    name: "13:00",
    status: "available",
  },
  {
    id: 12,
    name: "13:30",
    status: "available",
  },
  {
    id: 13,
    name: "14:00",
    status: "available",
  },
  {
    id: 14,
    name: "14:30",
    status: "available",
  },
  {
    id: 15,
    name: "15:00",
    status: "available",
  },
  {
    id: 16,
    name: "15:30",
    status: "available",
  },
  {
    id: 17,
    name: "16:00",
    status: "available",
  },
  {
    id: 18,
    name: "16:30",
    status: "available",
  },
  {
    id: 19,
    name: "17:00",
    status: "available",
  },
  {
    id: 20,
    name: "17:30",
    status: "available",
  },
];
const BookAppoinment = ({ idArea, idServico }) => {
  const [areaSelecionada, setAreaSelecionada] = useState("");
  const [serviceSelecionado, setServiceSelecionado] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [cpf, setCpf] = useState("");
  const [horario, setHorario] = useState("");
  const [data, setData] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");

  // Novos estados para o endereço
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [numero, setNumero] = useState("");

  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [ccv, setCcv] = useState("");
  const [installmentCount, setInstallmentCount] = useState(1);

  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState(1);
  const [servico, setServico] = useState(null); // Estado para armazenar o resultado da API

  // const nextStep = () => setStep(step + 1);
  // const prevStep = () => setStep(step - 1);

  const nextStep = () => {
    console.log("Step atual:", step);

    if (step === 1) {
      setStep(3); // Pula o passo 2 e vai direto para o passo 3
    } else if (step === 3) {
      setStep(4); // Do passo 3 vai para o 4 normalmente
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    console.log("Step atual:", step);

    if (step === 3) {
      setStep(1); // Pula o passo 2 e volta direto para o passo 1
    } else if (step === 4) {
      setStep(3); // Do passo 4 volta para o 3 normalmente
    } else {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    setAreaSelecionada(idArea);
    setServiceSelecionado(idServico);
  }, [idArea, idServico]);

  useEffect(() => {
    if (areaSelecionada && serviceSelecionado) {
      fetchServico(areaSelecionada, serviceSelecionado);
    }
  }, [areaSelecionada, serviceSelecionado]);

  const fetchServico = async (areaId, servicoId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_API}/subservicosPorId/${servicoId}`
      );
      const data = await response.json();
      console.log("Serviço buscado:", data);
      if (response.ok) {
        setServico(data); // Armazena o resultado na constante servico
      } else {
        console.error("Erro ao buscar o serviço:", data.message);
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  const alerta = (titulo, texto, icone) => {
    Swal.fire({
      title: titulo,
      text: texto,
      icon: icone,
      showCancelButton: false,
      confirmButtonColor: "#ff5e15",
      confirmButtonText: "Ok",
    });
  };

  const submitForm = () => {
    setLoading(true);

    console.log({
      nome,
      telefone,
      email,
      cpf,
      horario,
      data,
      cep,
      logradouro,
      bairro,
      cidade,
      estado,
      numero,
      paymentMethod,
      cardName,
      cardNumber,
      expiryDate,
      ccv,
    });

    if (!nome) {
      alerta("Campo obrigatório", "Por favor, preencha o campo Nome", "error");
      setLoading(false);
      setStep(3);
      return;
    }
    if (!telefone) {
      alerta(
        "Campo obrigatório",
        "Por favor, preencha o campo Telefone",
        "error"
      );
      setLoading(false);
      setStep(3);
      return;
    }
    if (!email) {
      alerta("Campo obrigatório", "Por favor, preencha o campo Email", "error");
      setLoading(false);
      setStep(3);
      return;
    }
    if (!cpf) {
      alerta("Campo obrigatório", "Por favor, preencha o campo CPF", "error");
      setLoading(false);
      setStep(3);
      return;
    }
    if (!horario) {
      alerta("Campo obrigatório", "Por favor, selecione um horário", "error");
      setLoading(false);
      setStep(1);
      return;
    }
    if (!data) {
      alerta("Campo obrigatório", "Por favor, selecione uma data", "error");
      setLoading(false);
      setStep(1);
      return;
    }
    if (!cep) {
      alerta("Campo obrigatório", "Por favor, preencha o campo CEP", "error");
      setLoading(false);
      setStep(3);
      return;
    }
    if (!logradouro) {
      alerta(
        "Campo obrigatório",
        "Por favor, preencha o campo ENDEREÇO",
        "error"
      );
      setLoading(false);
      setStep(3);
      return;
    }
    if (!bairro) {
      alerta(
        "Campo obrigatório",
        "Por favor, preencha o campo Bairro",
        "error"
      );
      setLoading(false);
      setStep(3);
      return;
    }
    if (!cidade) {
      alerta(
        "Campo obrigatório",
        "Por favor, preencha o campo Cidade",
        "error"
      );
      setLoading(false);
      setStep(3);
      return;
    }
    if (!estado) {
      alerta(
        "Campo obrigatório",
        "Por favor, preencha o campo Estado",
        "error"
      );
      setLoading(false);
      setStep(3);
      return;
    }
    if (!numero) {
      alerta(
        "Campo obrigatório",
        "Por favor, preencha o campo Número",
        "error"
      );
      setLoading(false);
      setStep(3);
      return;
    }
    if (!paymentMethod) {
      alerta(
        "Campo obrigatório",
        "Por favor, selecione um método de pagamento",
        "error"
      );
      setLoading(false);
      setStep(4);
      return;
    }
    if (paymentMethod === "creditCard") {
      if (!cardName) {
        alerta(
          "Campo obrigatório",
          "Por favor, preencha o campo Nome do Cartão",
          "error"
        );
        setLoading(false);
        setStep(4);
        return;
      }
      if (!cardNumber) {
        alerta(
          "Campo obrigatório",
          "Por favor, preencha o campo Número do Cartão",
          "error"
        );
        setLoading(false);
        setStep(4);
        return;
      }
      if (!expiryDate) {
        alerta(
          "Campo obrigatório",
          "Por favor, preencha o campo Data de Validade",
          "error"
        );
        setLoading(false);
        setStep(4);
        return;
      }
      if (!ccv) {
        alerta("Campo obrigatório", "Por favor, preencha o campo CCV", "error");
        setLoading(false);
        setStep(4);

        return;
      }
    }

    fetch(`${process.env.REACT_APP_URL_API}/teste`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        serviceId: serviceSelecionado,
        areaId: areaSelecionada,
        name: nome,
        phone: telefone,
        date: data,
        time: horario,
        cpf: cpf,
        email: email,
        message: mensagem,
        paymentMethod: paymentMethod,
        cardName: cardName,
        cardNumber: cardNumber,
        expiryDate: expiryDate,
        ccv: ccv,
        cep: cep,
        logradouro: logradouro,
        bairro: bairro,
        cidade: cidade,
        estado: estado,
        numero: numero,
        servico: servico,
        installmentCount: installmentCount,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        console.log("API Response:", data);

        if (data.error) {
          Swal.fire({
            icon: "error",
            title: "Não foi possível agendar a consulta",
            text: data.descricaoErro,
          });
          setLoading(false);
          return;
        }

        if (data) {
          localStorage.setItem("paymentMethod", paymentMethod);
          localStorage.setItem("resultCompra", JSON.stringify(data));
          setLoading(false);

          let timerInterval;
          Swal.fire({
            title: "Consulta agendada com sucesso!",
            html: "Redirecionando para a página de pagamento em <b></b>.",
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const timer = Swal.getPopup().querySelector("b");
              timerInterval = setInterval(() => {
                const timerValue = Swal.getTimerLeft() / 1000;
                timer.textContent = timerValue.toFixed(0);
              }, 100);
            },
            willClose: () => {
              clearInterval(timerInterval);
            },
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              window.location.href = "/finalizar";
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <>
      {loading && <ModalSpinner text={"Finalizando seu pagamento"} />}

      <section
        className="appointment-area appointment-area-3 appointment_page_bg pos-rel pt-115 pb-120"
        data-background="img/slider/capa.png"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="calculate-box white-bg">
                <div className="calculate-content">
                  <div className="row">
                    <div className="col-xl-12">
                      <div className="about-title news-letter-title mb-70">
                        <h5 className="pink-color">Comece hoje</h5>
                        <h1>Seja uma mulher Olis</h1>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {step === 1 && (
                      <StepOne
                        key={"stepOne"}
                        data={data}
                        setData={setData}
                        horario={horario}
                        setHorario={setHorario}
                        horarios={horarios}
                        nextStep={nextStep}
                      />
                    )}
                    {step === 2 && (
                      <StepTwo
                        key={"stepTwo"}
                        areaSelecionada={areaSelecionada}
                        setAreaSelecionada={setAreaSelecionada}
                        serviceSelecionado={serviceSelecionado}
                        setServiceSelecionado={setServiceSelecionado}
                        nextStep={nextStep}
                        prevStep={prevStep}
                      />
                    )}
                    {step === 3 && (
                      <StepThree
                        key={"stepThree"}
                        nome={nome}
                        setNome={setNome}
                        email={email}
                        setEmail={setEmail}
                        cpf={cpf}
                        setCpf={setCpf}
                        telefone={telefone}
                        setTelefone={setTelefone}
                        mensagem={mensagem}
                        setMensagem={setMensagem}
                        nextStep={nextStep}
                        prevStep={prevStep}
                        cep={cep} // Passa o CEP para o StepThree
                        setCep={setCep} // Passa o setter de CEP
                        logradouro={logradouro} // Passa o logradouro
                        setLogradouro={setLogradouro} // Passa o setter de logradouro
                        bairro={bairro} // Passa o bairro
                        setBairro={setBairro} // Passa o setter de bairro
                        cidade={cidade} // Passa a cidade
                        setCidade={setCidade} // Passa o setter de cidade
                        estado={estado} // Passa o estado
                        setEstado={setEstado} // Passa o setter de estado
                        numero={numero}
                        setNumero={setNumero}
                      />
                    )}
                    {step === 4 && (
                      <StepFour
                        key={"stepFour"}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        submitForm={submitForm}
                        prevStep={prevStep}
                        cardName={cardName}
                        setCardName={setCardName}
                        cardNumber={cardNumber}
                        setCardNumber={setCardNumber}
                        expiryDate={expiryDate}
                        setExpiryDate={setExpiryDate}
                        ccv={ccv}
                        setCcv={setCcv}
                        servico={servico}
                        installmentCount={installmentCount}
                        setInstallmentCount={setInstallmentCount}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BookAppoinment;
