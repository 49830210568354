import React from "react";
import HomeFourSingleHowWorksItem from "../../../components/HomeFourSingleHowWorksItem/HomeFourSingleHowWorksItem";

const HomeFourHowWorks = () => {
  return (
    <>
      <section className="howitworks pt-180 pb-80" id="howitworks">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
              <div className="section-title text-center pos-rel mb-75">
                <div className="section-icon">
                  {/* <img
                    className="section-back-icon"
                    src="img/section/section-back-icon.png"
                    alt=""
                  /> */}
                </div>
                <div className="section-text pos-rel">
                  <h5>Entenda como funciona</h5>
                  <h1>
                    Você será atendida por uma especialista em 3 passos simples
                  </h1>
                </div>
                {/* <div className="section-line pos-rel">
                  <img src="img/shape/section-title-line.png" alt="" />
                </div> */}
              </div>
            </div>
          </div>
          <div className="row pos-rel d-flex justify-content-between">
            <HomeFourSingleHowWorksItem
              icon="icone1"
              title="Navegue pela lista de serviços disponíveis"
              subtitle="Escolha o serviço que melhor atende as suas necessidades."
              moveIcon="move-icon"
            />
            <HomeFourSingleHowWorksItem
              icon="icone2"
              title="Selecione a data e horário convenientes para você"
              subtitle="Tenha a flexibilidade de reagendar ou cancelar, conforme necessário, dentro das políticas da plataforma."
              moveIcon="move-icon"
            />
            <HomeFourSingleHowWorksItem
              icon="icone3"
              title="Reúna-se Conosco"
              subtitle="No dia e horário agendados, conecte-se com o especialista para receber orientação e suporte personalizado."
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeFourHowWorks;
