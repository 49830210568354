import React from "react";
import HomeSIngleService from "../../../../components/HomeSIngleService/HomeSIngleService";

const ServicesOneABout = () => {
  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row ">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="section-title section-title-m-0 pos-rel mb-50 text-end">
                <div className="section-icon">
                  <img
                    className="section-back-icon back-icon-right"
                    src="img/section/section-back-icon.png"
                    alt=""
                  />
                </div>
                <div className="section-text section-text-small pos-rel">
                  <h5>Entenda mais</h5>
                  <h1>O que é a Ginecologia?</h1>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="facalty-text mb-50">
                <p>
                  A ginecologia é a especialidade médica que se dedica à saúde
                  da mulher e ao aparelho reprodutor feminino. O ginecologista é
                  o médico especialista que cuida da saúde da mulher, desde a
                  adolescência até a terceira idade.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesOneABout;
