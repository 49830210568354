import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { CreditCard, QrCode } from "@mui/icons-material";
import styled from "styled-components";
import InputMask from "react-input-mask"; // Importa InputMask
import Swal from "sweetalert2";

const sanitizeNumber = (value) => value.replace(/\D/g, "");
const sanitizeCcv = (value) => value.replace(/\D/g, "");
const sanitizeName = (value) => value.replace(/[^a-zA-Z\s]/g, "").toUpperCase();

const StyledTermsText = styled.span`
  color: #ff66cc;
  cursor: pointer;
  &:hover {
    color: #d40078;
  }
`;

const PaymentOptionCard = styled(Card)`
  margin: 10px;
  border: 2px solid ${(props) => (props.selected ? "#1976d2" : "transparent")};
  transition: border-color 0.3s;
  &:hover {
    border-color: #1976d2;
    cursor: pointer;
  }
`;

const StepFour = ({
  setPaymentMethod,
  submitForm,
  prevStep,
  cardName,
  setCardName,
  cardNumber,
  setCardNumber,
  expiryDate,
  setExpiryDate,
  ccv = { ccv },
  setCcv = { setCcv },
  servico = { servico },
  installmentCount = { installmentCount },
  setInstallmentCount = { setInstallmentCount },
}) => {
  const [selectedMethod, setSelectedMethod] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);
  const [isCancellationAccepted, setIsCancellationAccepted] = useState(false);

  const [localInstallmentCount, setLocalInstallmentCount] = useState(
    installmentCount || ""
  );

  const handlePaymentMethodChange = (method) => {
    setSelectedMethod(method);
    setPaymentMethod(method);
  };

  const handleInstallmentChange = (e) => {
    setLocalInstallmentCount(e.target.value);
    setInstallmentCount(e.target.value);
  };

  const valorFixo = servico?.Preco;
  const valorFixoFormatado = Number(valorFixo)?.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const ParcelasServicos = servico?.Parcelas;

  const gerarOpcoesParcelas = () => {
    const parcelas = [];
    if (!ParcelasServicos) return;
    for (let i = 0; i <= ParcelasServicos.length; i++) {
      const parcelaescolhida = ParcelasServicos[i];
      if (!parcelaescolhida) continue;

      let valorDividido =
        parcelaescolhida.ValorParcela / parcelaescolhida.QuantidadeParcelas;

      valorDividido = valorDividido.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });

      parcelas.push(
        <MenuItem key={i} value={parcelaescolhida.QuantidadeParcelas}>
          {parcelaescolhida.QuantidadeParcelas}x de R$ {valorDividido}
        </MenuItem>
      );
    }
    return parcelas;
  };

  const validateCardForm = () => {
    const errors = {};

    if (!cardName || cardName.trim() === "") {
      errors.cardName = "Nome no cartão é obrigatório";
    } else {
      errors.cardName = null;
    }

    const sanitizedCardNumber = sanitizeNumber(cardNumber);
    if (sanitizedCardNumber.length < 13 || sanitizedCardNumber.length > 19) {
      errors.cardNumber = "Número do cartão inválido";
    }

    const sanitizedCcv = sanitizeCcv(ccv);
    if (sanitizedCcv.length < 3 || sanitizedCcv.length > 4) {
      errors.ccv = "CCV inválido";
    }

    setFormErrors(errors);
    return Object.keys(errors).filter((key) => errors[key]).length === 0;
  };

  const handleSubmit = () => {
    if (!isPrivacyAccepted || !isConsentAccepted || !isCancellationAccepted) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Você deve aceitar os termos de uso, a política de privacidade, o termo de consentimento para atendimento online e a política de cancelamento antes de prosseguir.",
      });

      return;
    }

    if (selectedMethod === "PIX") {
      submitForm();
      return;
    }

    if (selectedMethod === "CREDIT_CARD" && !validateCardForm()) {
      return;
    }

    submitForm();
  };

  const openPagePrivacy = () => {
    window.open("/politicaPrivacidade", "_blank");
  };

  const openPageCancellation = () => {
    window.open("/politicaCancelamento", "_blank");
  };

  const openPageTerms = () => {
    window.open("/termosGerais", "_blank");
  };

  return (
    <>
      <div className="col-xl-12">
        <h3>Escolha o Meio de Pagamento</h3>
        <Typography variant="h5" component="div" style={{ marginBottom: 20 }}>
          Valor do serviço: {valorFixoFormatado}
        </Typography>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <PaymentOptionCard
              selected={selectedMethod === "PIX"}
              onClick={() => handlePaymentMethodChange("PIX")}
            >
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item>
                    <QrCode style={{ fontSize: 40 }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="div"
                      style={{ marginLeft: 10 }}
                    >
                      PIX
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </PaymentOptionCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PaymentOptionCard
              selected={selectedMethod === "CREDIT_CARD"}
              onClick={() => handlePaymentMethodChange("CREDIT_CARD")}
            >
              <CardContent>
                <Grid container alignItems="center">
                  <Grid item>
                    <CreditCard style={{ fontSize: 40 }} />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      component="div"
                      style={{ marginLeft: 10 }}
                    >
                      Cartão de Crédito
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </PaymentOptionCard>
          </Grid>
        </Grid>
      </div>

      {selectedMethod === "CREDIT_CARD" && (
        <div className="col-xl-12">
          <form className="calculate-form appointment-form-3 mb-20">
            <TextField
              fullWidth
              label="Nome no cartão"
              value={cardName}
              onChange={(e) => setCardName(sanitizeName(e.target.value))}
              name="cardName"
              margin="normal"
              error={!!formErrors.cardName}
              helperText={formErrors.cardName}
            />
            <TextField
              fullWidth
              label="Número do cartão"
              value={cardNumber}
              onChange={(e) => setCardNumber(sanitizeNumber(e.target.value))}
              name="cardNumber"
              margin="normal"
              error={!!formErrors.cardNumber}
              helperText={formErrors.cardNumber}
            />

            <InputMask
              mask="99/99"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              maskChar={null}
            >
              {() => (
                <TextField
                  fullWidth
                  label="Data de validade (MM/AA)"
                  margin="normal"
                  error={!!formErrors.expiryDate}
                  helperText={formErrors.expiryDate}
                />
              )}
            </InputMask>

            <TextField
              fullWidth
              label="CCV"
              value={ccv}
              onChange={(e) => setCcv(sanitizeCcv(e.target.value))}
              name="ccv"
              margin="normal"
              error={!!formErrors.ccv}
              helperText={formErrors.ccv}
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="installments-label">Parcelas</InputLabel>
              <Select
                labelId="installments-label"
                value={localInstallmentCount || ""}
                onChange={handleInstallmentChange}
              >
                {gerarOpcoesParcelas()}
              </Select>
            </FormControl>
          </form>
        </div>
      )}

      {/* Checkbox com links para as políticas */}
      <FormControlLabel
        control={
          <Checkbox
            checked={isPrivacyAccepted}
            onChange={() => setIsPrivacyAccepted(!isPrivacyAccepted)}
            color="primary"
          />
        }
        label={
          <Typography variant="body2">
            Eu li e aceito os{" "}
            <StyledTermsText
              onClick={() => window.open("/politicaPrivacidade", "_blank")}
            >
              Termos gerais de uso e a política de privacidade
            </StyledTermsText>
          </Typography>
        }
        style={{ marginTop: 20 }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={isConsentAccepted}
            onChange={() => setIsConsentAccepted(!isConsentAccepted)}
            color="primary"
          />
        }
        label={
          <Typography variant="body2">
            Eu li e aceito os{" "}
            <StyledTermsText
              onClick={() => window.open("/termoConsentimento", "_blank")}
            >
              Termos de consentimento para atendimento online
            </StyledTermsText>
          </Typography>
        }
        style={{ marginTop: 20 }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={isCancellationAccepted}
            onChange={() => setIsCancellationAccepted(!isCancellationAccepted)}
            color="primary"
          />
        }
        label={
          <Typography variant="body2">
            Eu li e aceito a{" "}
            <StyledTermsText
              onClick={() => window.open("/politicaCancelamento", "_blank")}
            >
              Política de cancelamento
            </StyledTermsText>
          </Typography>
        }
        style={{ marginTop: 20 }}
      />

      <button className="primary_btn mt-40" onClick={handleSubmit}>
        Próximo
      </button>
      <button className="secondary_btn mt-40" onClick={prevStep}>
        Anterior
      </button>
    </>
  );
};

export default StepFour;
