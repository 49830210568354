import React from "react";

const areas = [
  { id: 1, name: "Ginecologia" },
  { id: 2, name: "Obstetrícia" },
];

const services = [
  { id: 1, areaId: 1, name: "Primeira consulta ginecológica" },
  { id: 2, areaId: 1, name: "Rotina Ginecológica" },
  { id: 3, areaId: 1, name: "Anticoncepção" },
  { id: 4, areaId: 1, name: "SOS Gineco!" },
  { id: 5, areaId: 1, name: "Avaliação da Fertilidade" },
  { id: 6, areaId: 1, name: "Investigação da Infertilidade" },
  { id: 7, areaId: 2, name: "Consultoria pré-natal a distância" },
  { id: 8, areaId: 2, name: "Suplementação Pré-concepcional" },
  { id: 9, areaId: 2, name: "Suplementação Gestacional" },
  { id: 10, areaId: 2, name: "Suplementação Pós-parto" },
  { id: 11, areaId: 2, name: "Diabetes Gestacional" },
  { id: 12, areaId: 1, name: "Reposição hormonal na menopausa" },
];

const StepTwo = ({
  areaSelecionada,
  setAreaSelecionada,
  serviceSelecionado,
  setServiceSelecionado,
  nextStep,
  prevStep,
}) => {
  const filteredServices = services.filter(
    (service) => service.areaId === Number(areaSelecionada)
  );

  return (
    <>
      <h3 className="mb-20">Selecione a área e o serviço</h3>

      <div className="col-xl-6 col-lg-6 col-md-6">
        <select
          value={areaSelecionada}
          name="area"
          className="form-select select_style"
          onChange={(e) => {
            setAreaSelecionada(e.target.value);
            setServiceSelecionado(""); // Reset service selection when area changes
          }}
        >
          <option value="" selected>
            Área
          </option>
          {areas.map((area) => (
            <option key={area.id} value={area.id}>
              {area.name}
            </option>
          ))}
        </select>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6">
        <select
          value={serviceSelecionado}
          name="service"
          onChange={(e) => setServiceSelecionado(e.target.value)}
          className="form-select select_style"
          disabled={!areaSelecionada}
        >
          <option value="" disabled>
            Serviço
          </option>
          {filteredServices.map((service) => (
            <option key={service.id} value={service.id}>
              {service.name}
            </option>
          ))}
        </select>
      </div>
      <button
        className="primary_btn mt-40"
        onClick={nextStep}
        disabled={!serviceSelecionado}
      >
        Próximo
      </button>
      <button className="secondary_btn mt-40" onClick={prevStep}>
        Anterior
      </button>
    </>
  );
};

export default StepTwo;
