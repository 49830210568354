import React from "react";

const TermsOfUse = () => {
  return (
    <div
      className="container"
      style={{ marginBottom: "150px", marginTop: "100px" }}
    >
      <div className="row">
        <div className="col-12">
          <h1>TERMOS GERAIS DE USO DAS PLATAFORMAS OLIS SAÚDE</h1>

          <p>Seja bem-vindo!</p>
          <p>
            As Plataformas são disponibilizadas e gerenciadas pela OLIS SAÚDE
            FEMININA LTDA., com sede na Rua Augusta, nº 890, Consolação, São
            Paulo/SP, inscrita no CNPJ sob o nº 47.205.725/0001-80 oferece a
            você acesso a um portfólio de funcionalidades, produtos e serviços
            (“Serviços”) por meio do site “
            <a href="http://www.OLISSAUDEmulher.com">www.OLISSAUDEmulher.com</a>
            ”, de acordo com os TERMOS DE USO.
          </p>

          <p>
            Estes Termos Gerais de Uso (“Termos de Uso”) disciplinam o acesso,
            uso e funcionamento das plataformas digitais da OLIS SAÚDE,
            incluindo o site “
            <a href="http://www.mulherOLISSAUDE.com.br">
              www.mulherOLISSAUDE.com.br
            </a>
            ” e um aplicativo. Por isso pedimos que você leia com atenção, pois
            o uso das plataformas está sujeito à sua concordância e aceitação,
            bem como à Política de Privacidade da OLIS SAÚDE (“
            <a href="#">POLÍTICA DE PRIVACIDADE</a>”) .
          </p>

          <p>
            Para fins destes Termos de Uso, “Usuário” ou “Você” significa todo e
            qualquer indivíduo que faça uso ou tenha acesso às Plataformas ou ao
            seu conteúdo ou que contrate os Serviços. A Política de Privacidade
            também se faz disponível nessa plataforma.
          </p>

          <p>
            Estes Termos de Uso foram atualizados pela última vez em março de
            2024. Sobre as Plataformas que a OLIS SAÚDE disponibiliza a Você, o
            acesso a funcionalidades, conteúdos, produtos e serviços, prestados
            por ela e/ou por seus parceiros (“Serviços”), incluindo, mas não se
            limitando a produtos de assistência à saúde, serviços médicos,
            teleconsultas, avaliação de exames médicos, funcionalidades para
            acompanhamento de ciclo menstrual, monitoramento de saúde e
            autoconscientização corporal, e conteúdos informativos.
          </p>

          <h2>1. Serviços</h2>
          <p>
            Os Serviços ofertados pela OLIS SAÚDE podem incluir, conforme o
            contexto:
          </p>
          <ul>
            <li>Contratação online de produtos e serviços</li>
            <li>
              Serviços básicos de assistência primária à saúde via
              teleatendimento profissional
            </li>
            <li>Consulta domiciliar</li>
            <li>
              Acesso a informações médicas e de saúde, bem como histórico do
              Usuário
            </li>
            <li>
              Funcionalidades de monitoramento de saúde, acompanhamento de ciclo
              menstrual, e autoconscientização corporal
            </li>
            <li>Conteúdos informativos</li>
            <li>
              Outras funcionalidades, produtos ou serviços que venham a ser
              disponibilizados nas Plataformas, de tempos em tempos
            </li>
          </ul>

          <p>
            Para garantir a qualidade das Plataformas, novas funcionalidades
            podem ser incluídas em atualizações e novas versões das Plataformas,
            assim como funcionalidades antigas podem deixar de existir e/ou
            sofrer limitações.
          </p>

          <h2>2. Condições de Uso</h2>
          <p>
            O uso dos Serviços disponibilizados pelas Plataformas dependerá
            necessariamente de equipamentos e dispositivos conectados a uma rede
            internet...
          </p>

          <h3>2.1 Cadastro</h3>
          <p>
            Para ter acesso às Plataformas e aos Serviços disponíveis de maneira
            completa, Você deverá se cadastrar e manter uma conta pessoal e
            intransferível de Usuário dos Serviços e das Plataformas (“Conta de
            Usuário”). Para criar sua Conta de Usuário, Você deverá preencher um
            formulário disponibilizado pela OLIS SAÚDE, informando:
          </p>
          <ul>
            <li>Nome completo</li>
            <li>E-mail</li>
            <li>Número de telefone</li>
            <li>Data de nascimento</li>
            <li>Idade</li>
            <li>Endereço completo</li>
            <li>Nome de usuário</li>
            <li>Senha de acesso</li>
          </ul>

          <h2>3. Responsabilidade dos Usuários</h2>
          <p>
            Ao utilizar as Plataformas, Você concorda que é responsável por
            manter a confidencialidade de sua Conta de Usuário e senha de
            acesso...
          </p>

          <h2>4. Privacidade e Proteção de Dados Pessoais</h2>
          <p>
            O acesso e utilização das plataformas, assim como dos serviços,
            sujeita o usuário ao tratamento de determinadas informações...
          </p>

          <h2>5. Limitação de Responsabilidade</h2>
          <p>
            De maneira geral e na máxima extensão permitida pela lei, a OLIS
            SAÚDE se exime de quaisquer garantias e/ou responsabilidades...
          </p>

          <h2>6. Propriedade Intelectual</h2>
          <p>
            A OLIS SAÚDE detém todos direitos de propriedade intelectual
            relacionados às Plataformas, incluindo, mas não se limitando a...
          </p>

          <h2>7. Links de Terceiros</h2>
          <p>
            As Plataformas poderão manter links ou redirecionar o Usuário a
            sites de terceiros, o que não significa que esses sites sejam...
          </p>

          <h2>8. Atendimento ao Usuário</h2>
          <p>
            Caso Você tenha dúvidas, reclamações, sugestões acerca dos Serviços
            e/ou das Plataformas, ou deseje obter maiores informações...
          </p>

          <h2>9. Direito de Arrependimento e Cancelamento</h2>
          <p>
            Após a confirmação do Cadastro, o Usuário poderá exercer o seu
            direito de arrependimento em até 07 (sete) dias corridos...
          </p>

          <h2>10. Disposições Gerais</h2>
          <p>
            <strong>a) Comunicações Eletrônicas.</strong> Ao utilizar as
            Plataformas, contratar Serviços ou nos enviar e-mails ou outros
            tipos de comunicação...
          </p>
          <p>
            <strong>b) Força Maior.</strong> A OLIS SAÚDE não será
            responsabilizada por descumprimento ou cumprimento inadequado
            causado por uma situação de caso fortuito ou de força maior...
          </p>
          <p>
            <strong>c) Independência das Disposições.</strong> Caso qualquer
            disposição destes Termos de Uso seja considerada inexequível ou
            inválida...
          </p>
          <p>
            <strong>d) Foro e Lei Aplicável.</strong> Estes Termos de Uso serão
            regidos pelas leis da República Federativa do Brasil...
          </p>
          <p>
            <strong>e) Consentimento.</strong> Ao aceitar estes Termos de Uso,
            você declara ter capacidade legal e reconhece de forma expressa e
            inequívoca...
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
