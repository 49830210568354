import React from "react";

const CommonSectionArea = ({
  area_header,
  title,
  descricao,
  price,
  idArea,
  idServico,
  pacotes,
}) => {
  const url = `/agendamento/${idArea}/${idServico}`;

  const handleFormatMoney = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  return (
    <>
      <section className="about-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-7  order-2 order-lg-1">
              <div className="about-right-side pt-55 mb-30">
                <div className="about-title mb-20">
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <div className="about-text mb-50">
                  {/* <p>{descricao}</p> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: descricao,
                    }}
                  />
                </div>
                {price && (
                  <div className="about-text">
                    <h3>{price}</h3>
                  </div>
                )}
                {pacotes && (
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-12">
                        <h2 className="">Confira Nossos Pacotes</h2>
                        {pacotes.map((pacote, index) => (
                          <div key={index} className="mb-4">
                            <h4>
                              {pacote.titulo} -{" "}
                              {handleFormatMoney(pacote.preco)}
                            </h4>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: pacote.descricao,
                              }}
                            />
                            <a
                              href={pacote.url}
                              className="primary_btn btn-icon ml-0"
                            >
                              <span>+</span> Agendar agora
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {!pacotes && (
                  <div className="about-btn mt-4">
                    <a href={url} className="primary_btn ml-0">
                      Agendar agora
                    </a>
                  </div>
                )}

                {/* <span>Consulta online • Relatório personalizado</span> */}

                <div className="our-destination d-none">
                  <div className="single-item mb-30">
                    <div className="mv-icon f-left">
                      <img src="img/about/destination-icon-1.png" alt="" />
                    </div>
                    <div className="mv-title fix">
                      <h3>Our Mission</h3>
                      <p>
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse.
                      </p>
                    </div>
                  </div>
                  <div className="single-item">
                    <div className="mv-icon f-left">
                      <img src="img/about/destination-icon-2.png" alt="" />
                    </div>
                    <div className="mv-title fix">
                      <h3>Our Vission</h3>
                      <p>
                        Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate
                        velit esse.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-5  order-1 order-lg-2">
              {area_header}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CommonSectionArea;
