import React, { useState, useRef } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const MetodoCarrossel = ({ metodos }) => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const detailsRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 768, // Tamanho da tela em pixels (pode ajustar conforme necessário)
        settings: {
          slidesToShow: 1, // Mostra 1 slide por vez
          slidesToScroll: 1, // Desliza 1 slide por vez
          arrows: false, // Remove setas para mais espaço em telas menores
        },
      },
    ],
  };
  const handleCardClick = (method) => {
    setSelectedMethod(method);
    console.log(method);
    window.location.href = method.url;
  };

  return (
    <>
      <div className="section-faq-area pt-120 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="about-title mb-45">
                <h5 className="pink-color">Nossos Serviços</h5>
                <h1>Confira os métodos de contracepção.</h1>
              </div>
              <div className="faq-right-box">
                <Slider
                  className="h4service-active h5service-active h5_services_slider"
                  {...settings}
                >
                  {metodos.map((method, index) => {
                    const { imgUrl, metodo, preco, durabilidade, custoPorDia } =
                      method;
                    return (
                      <div className="h4service-item" key={index}>
                        <div className="h4service-box white-bg mb-30">
                          <div className="service-thumb pos-rel mb-0">
                            <img src={imgUrl} alt={metodo} />
                            <Link
                              className="h4services-tag green-bg white-color text-uppercase f-700"
                              to="#"
                            >
                              {metodo}
                            </Link>
                          </div>
                          <div className="service-content h4services-content h6services-content">
                            <h3>{metodo}</h3>
                            <p className="mb-20">
                              <strong>Preço:</strong> {preco}
                            </p>
                            <p>
                              <strong>Durabilidade:</strong> {durabilidade}
                            </p>
                            <p>
                              <strong>Custo por dia:</strong> {custoPorDia}
                            </p>
                            <button
                              className="btn primary_btn mt-40"
                              onClick={() => handleCardClick(method)}
                            >
                              Agende agora
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>

          {selectedMethod && (
            <div className="method-details mt-5" ref={detailsRef}>
              <div className="about-title mb-45">
                <h1>Detalhes do Método Selecionado</h1>
              </div>
              <div className="details-content white-bg p-4">
                <h3>{selectedMethod.metodo}</h3>
                <p>
                  <strong>Eficácia:</strong> {selectedMethod.eficacia}
                </p>
                <p>
                  <strong>Preço:</strong> {selectedMethod.preco}
                </p>
                <p>
                  <strong>Durabilidade:</strong> {selectedMethod.durabilidade}
                </p>
                <p>
                  <strong>Custo por dia:</strong> {selectedMethod.custoPorDia}
                </p>
                <p>
                  <strong>Mecanismo de ação:</strong> {selectedMethod.mecanismo}
                </p>
                <p>
                  <strong>Inserção:</strong> {selectedMethod.insercao}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MetodoCarrossel;
