import React from "react";
import HomeAboutArea from "./HomeAboutArea/HomeAboutArea";
import HomeBlogs from "./HomeBlogs/HomeBlogs";
import HomeCta from "./HomeCta/HomeCta";
import HomeFact from "./HomeFact/HomeFact";
import HomeFooter from "./HomeFooter/HomeFooter";
import HomeHeroSection from "./HomeHeroSection/HomeHeroSection";
import HomeOurTeam from "./HomeOurTeam/HomeOurTeam";
import HomePricing from "./HomePricing/HomePricing";
import HomeServices from "./HomeServices/HomeServices";
import Checkout from "./checkout";
import HomeFiveHowWorks from "../../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeFiveMedicalTab from "../../HomeFive/HomeFiveMedicalTab/HomeFiveMedicalTab";
import HomeFiveSingleMedicalTab from "../../../components/HomeFiveSingleMedicalTab/HomeFiveSingleMedicalTab";
import Ginecologia from "../../../components/areasOlis/ginecologia";
import Obstetricia from "../../../components/areasOlis/obstetricia";
import HomeHeader from "../../Home/Home/HomeHeader/HomeHeader";

const Finalizar = () => {
  return (
    <>
      <HomeHeader />
      <Checkout />

      {/* <HomeFiveHowWorks /> */}
      {/* <HomeFact /> */}
      {/* <HomePricing /> */}
      {/* <HomeCta /> */}
      {/* <HomeBlogs /> */}
      <HomeFooter />
    </>
  );
};

export default Finalizar;
