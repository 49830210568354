import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import styled from "styled-components";
import { useParams } from "react-router-dom"; // Para acessar o idConsulta da URL
import Swal from "sweetalert2";

// Estilo do Card de resumo
const SummaryCard = styled(Card)`
  margin: 20px;
  padding: 20px;
`;

const StepFour = ({ prevStep, submitForm }) => {
  const { idConsulta } = useParams(); // Pega o idConsulta da URL
  const [consulta, setConsulta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false); // Estado do modal

  // Função para buscar os dados da consulta
  const fetchConsulta = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_API}/consulta/${idConsulta}`
      );
      const data = await response.json();
      if (response.ok) {
        setConsulta(data);
      } else {
        Swal.fire(
          "Erro",
          "Não foi possível buscar os dados da consulta",
          "error"
        );
      }
    } catch (error) {
      Swal.fire("Erro", "Erro na comunicação com o servidor", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConsulta(); // Busca os dados ao carregar o componente
  }, [idConsulta]);

  const handleConfirm = () => {
    submitForm(); // Finaliza a remarcação
  };

  if (loading) {
    return <Typography>Carregando...</Typography>;
  }

  if (!consulta) {
    return <Typography>Consulta não encontrada.</Typography>;
  }

  return (
    <>
      <SummaryCard>
        <Typography variant="h5" gutterBottom>
          Resumo da Consulta de {consulta.cliente}
        </Typography>

        <Typography variant="body1">
          <strong>Telefone que entraremos em contato:</strong>{" "}
          {consulta.telefone}
        </Typography>
        <Typography variant="body1">
          <strong>Médico:</strong> {consulta.medico}
        </Typography>
        <Typography variant="body1">
          <strong>Área:</strong> {consulta.area}
        </Typography>
        <Typography variant="body1">
          <strong>Serviço:</strong> {consulta.servico}
        </Typography>

        <Typography variant="body1">
          <strong>Tipo de Consulta:</strong> {consulta.tipoConsulta}
        </Typography>
      </SummaryCard>

      <Button
        variant="contained"
        color="primary"
        className="primary_btn"
        onClick={handleConfirm}
        style={{ margin: "20px", backgroundColor: "#dd5cac" }}
      >
        Confirmar Remarcação
      </Button>

      <Button variant="outlined" onClick={prevStep} style={{ margin: "20px" }}>
        Voltar
      </Button>

      {/* Modal de Política de Privacidade */}
      <Dialog
        open={openPrivacyModal}
        onClose={() => setOpenPrivacyModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Política de Privacidade</DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Aqui vai o conteúdo da política de privacidade. Descreva como os
            dados são tratados, armazenados e protegidos.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenPrivacyModal(false)}
            className="primary"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StepFour;
