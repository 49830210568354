// StepOne.js
import React from "react";

const StepOne = ({
  data,
  setData,
  horario,
  setHorario,
  horarios,
  nextStep,
}) => (
  <>
    <div className="col-xl-6 col-lg-6 col-md-6">
      <label>Selecione uma data</label>
      <form className="calculate-form appointment-form-3 mb-20">
        <input
          name="date"
          value={data}
          onChange={(e) => setData(e.target.value)}
          type="date"
          placeholder="Selecione uma data"
        />
      </form>
    </div>
    <div className="col-xl-6 col-lg-6 col-md-6">
      <label>Selecione um horário</label>
      <select
        value={horario}
        name="time"
        onChange={(e) => setHorario(e.target.value)}
        className="form-select select_style"
        aria-label="Default select example"
      >
        {horarios.map((item) => (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
    <button className="primary_btn mt-40" onClick={nextStep}>
      Próximo
    </button>
  </>
);

export default StepOne;
