import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const SuplementacaoGestacao = () => {
  const title = "Suplementação na Gestação";
  const descricao =
    "Os 1.100 dias mais importantes da nutrição: uma janela de oportunidades para a saúde e o desenvolvimento do seu bebê. Alcance o potencial genético das próximas gerações, se preparando desde o período pré-concepcional, gestacional e pós-parto.";
  const price = "";

  const fatos = {
    titulo_fatos: "Avaliação Personalizada na gestação",
    descricao_fatos:
      "Realizamos uma avaliação completa do seu estado nutricional através de consultas online e exames laboratoriais, identificando possíveis deficiências e necessidades específicas. Suplementamos os nutrientes essenciais que podem não ser obtidos em quantidades adequadas apenas através da alimentação.",
    subtitulos: [
      {
        titulo: "Energia, Vitalidade e bom humor",
        subtitulo:
          "Sabemos que  a suplementação adequada pode aumentar os níveis de energia, ajudando a combater a fadiga, além de  melhorar o humor para manutenção da vitalidade materna.",
      },
      {
        titulo: "Apoio à Imunidade",
        subtitulo:
          "A suplementação para uma boa nutrição  fortalece o seu sistema imunológico, ajudando a combater infecções durante a gravidez, o que é crucial para o seu bem-estar e do seu  bebê.",
      },
    ],
  };

  const passos = [
    {
      titulo: "Escolha do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Recebimento da solicitação de exames laboratoriais",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Com resultado dos seus exames em mãos, agende a sua consulta",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Consulta realizada por videochamada (45 min)",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Recebimento da suplementação de micronutrientes via WhatsApp",
      descricao: "Realize os exames",
    },
    {
      titulo: "Acompanhamento via WhatsApp por 15 dias após consulta",
      descricao: "Receba o relatório médico",
    },
  ];

  const pacotes = [
    {
      titulo: "Pacote 1",
      descricao:
        "Uma consultoria avulsa. Nesta opção, você fica à vontade para escolher o momento de uma consultoria. Conversaremos por 1 hora.",
      preco: "399",
      url: "/agendamento/2/28",
    },
    {
      titulo: "Pacote 2",
      descricao:
        "Duas consultorias, com intervalo mínimo de um mês. Conversaremos por 1 hora em cada consultoria.",
      preco: "699",
      url: "/agendamento/2/29",
    },
    {
      titulo: "Pacote 3",
      descricao:
        "Três consultorias (1 a cada trimestre), com intervalo mínimo de um mês. Conversaremos por 1 hora em cada consultoria. Você ganhará um guia com orientações de alimentação para consultar durante toda a gestação!",
      preco: "999",
      url: "/agendamento/2/30",
    },
  ];

  const idArea = 3; // Ajuste o ID conforme necessário
  const idServico = 8; // Ajuste o ID conforme necessário

  const imgUrl = "img/services/Suplementacao.png";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="Suplementação na Gestação" subtitle="Serviço" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        pacotes={pacotes}
        imgUrl={imgUrl}
      />
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}
      <HomeThreeFaq />

      <HomeFooter />
    </>
  );
};

export default SuplementacaoGestacao;
