import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";
import HomeFact from "../Home/Home/HomeFact/HomeFact";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import AboutAnalysis from "./AboutAnalysis/AboutAnalysis";
import AboutAppoinment from "./AboutAppoinment/AboutAppoinment";
import AboutArea from "./AboutArea/AboutArea";
import HomeFiveHowWorks from "../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeThreeFaq from "../HomeThree/HomeThreeFaq/HomeThreeFaq";

const DiabetesGestacional = () => {
  const title = "Diabetes Gestacional";
  const descricao =
    "Mulher Olis, não deixe que o diagnóstico do diabetes gestacional a intimide. Junte-se a nós e ganhe confiança para enfrentar essa fase da sua jornada materna com conhecimento e apoio. Estamos aqui para ajudá-la a cada passo do caminho.";
  const price = "R$399,00 ou 6x R$79,90";

  const fatos = {
    titulo_fatos: "Empodere-se com Conhecimento",
    descricao_fatos:
      "Você sabia que a diabetes na gestação acomete cerca de 18% das mulheres no Brasil? É uma condição que pode trazer complicações para sua saúde e também do seu bebê. Como consequência, temos o ganho de peso exagerado, hipoglicemias após o nascimento e até predisposição à obesidade e diabetes no futuro.",
    subtitulos: [
      {
        titulo: "Acompanhamento personalizado",
        subtitulo:
          "Nos adaptamos às suas necessidades individuais, levando em consideração fatores como idade gestacional, peso, níveis de atividade física e preferências alimentares para adequarmos o melhor tratamento para sua realidade.",
      },
      {
        titulo: "Facilidade de acesso",
        subtitulo:
          "Experimente a conveniência de ter acesso a orientações e suporte online, que podem ser acessados a qualquer momento e de qualquer lugar, sem a necessidade de deslocamento para consultas presenciais.",
      },
    ],
  };

  const passos = [
    {
      titulo: "Agendamento do serviço",
      descricao: "Escolha o melhor horário para você",
    },
    {
      titulo: "Envio de exames prévios via WhatsApp, se houver",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Recebimento do Link de Atendimento, via Whatsapp via WhatsApp",
      descricao: "Receba o link de atendimento",
    },
    {
      titulo: "Consulta realizada por videochamada (45 min)",
      descricao: "Aguarde o retorno",
    },
    {
      titulo: "Solicitação de exames, se necessário",
      descricao: "Realize os exames",
    },
    {
      titulo: "Envio de relatório Médico",
      descricao: "Receba o relatório médico",
    },
  ];

  const idArea = 2; // Ajuste o ID conforme necessário
  const idServico = 11; // Ajuste o ID conforme necessário

  const imgUrl = "img/services/PreNatal.PNG";

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="Diabetes Gestacional" subtitle="Serviço" /> */}
      <AboutArea
        title={title}
        descricao={descricao}
        price={price}
        idArea={idArea}
        idServico={idServico}
        imgUrl={imgUrl}
      />
      {/* <AboutCounter /> */}
      <HomeFact fatos={fatos} />
      <AboutAppoinment passos={passos} />
      {/* <HomeOurTeam /> */}
      <HomeThreeFaq />
      <HomeFooter />
    </>
  );
};

export default DiabetesGestacional;
