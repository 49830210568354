import React from "react";
import HomeAboutArea from "./HomeAboutArea/HomeAboutArea";
import HomeBlogs from "./HomeBlogs/HomeBlogs";
import HomeCta from "./HomeCta/HomeCta";
import HomeFact from "./HomeFact/HomeFact";
import HomeFooter from "./HomeFooter/HomeFooter";
import HomeHeader from "./HomeHeader/HomeHeader";
import HomeHeroSection from "./HomeHeroSection/HomeHeroSection";
import HomeOurTeam from "./HomeOurTeam/HomeOurTeam";
import HomePricing from "./HomePricing/HomePricing";
import HomeServices from "./HomeServices/HomeServices";
import HomeFourAbout from "../../HomeFour/HomeFourAbout/HomeFourAbout";
import HomeFiveHowWorks from "../../HomeFive/HomeFiveHowWorks/HomeFiveHowWorks";
import HomeFiveMedicalTab from "../../HomeFive/HomeFiveMedicalTab/HomeFiveMedicalTab";
import HomeFiveSingleMedicalTab from "../../../components/HomeFiveSingleMedicalTab/HomeFiveSingleMedicalTab";
import Ginecologia from "../../../components/areasOlis/ginecologia";
import Obstetricia from "../../../components/areasOlis/obstetricia";
import HomeThreeFaq from "../../HomeThree/HomeThreeFaq/HomeThreeFaq";

const Home = () => {
  return (
    <>
      <HomeHeader />
      <HomeHeroSection />
      <HomeFourAbout />
      <Ginecologia />
      <Obstetricia />
      {/* <HomeFiveMedicalTab /> */}
      <HomeFiveHowWorks />
      <HomeThreeFaq />
      {/* <HomeServices /> */}
      <HomeOurTeam />
      {/* <HomeFact /> */}
      {/* <HomePricing /> */}
      {/* <HomeCta /> */}
      {/* <HomeBlogs /> */}
      <HomeFooter />
    </>
  );
};

export default Home;
