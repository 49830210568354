import React from "react";
import CommonPageHeader from "../../components/CommonPageHeader/CommonPageHeader";
import Footer from "../../components/Shared/Footer";
import HomeBlogs from "../Home/Home/HomeBlogs/HomeBlogs";
import HomeHeader from "../Home/Home/HomeHeader/HomeHeader";
import AppointmentArea from "./AppointmentArea/AppointmentArea";
import BookAppoinment from "./BookAppoinment/BookAppoinment";
import { useParams } from "react-router-dom";
import HomeFooter from "../Home/Home/HomeFooter/HomeFooter";

const Remarcacao = () => {
  const { idArea, idServico } = useParams();

  return (
    <>
      <HomeHeader />
      {/* <CommonPageHeader title="Agende agora" subtitle="Agenda" /> */}
      {/* <AppointmentArea /> */}
      <BookAppoinment idArea={idArea} idServico={idServico} />
      {/* <HomeBlogs /> */}
      <HomeFooter />
    </>
  );
};

export default Remarcacao;
