import React from "react";
import { Link } from "react-router-dom";
import HomeSingleFooterBlog from "../HomeSingleFooterBlog/HomeSingleFooterBlog";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-top primary-bg footer-map pos-rel pt-120 pb-80">
          <div className="container">
            <div
              className="row align-items-center"
              style={{ position: "relative", zIndex: "9999" }}
            >
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-contact-info footer-contact-info-3 mb-40">
                  <div className="footer-logo mb-35">
                    <Link to="/">
                      <img src="img/logo/logo.png" alt="" />
                    </Link>
                  </div>
                  <div className="footer-contact-content mb-25">
                    <p>
                      Sua Jornada de Saúde Feminina Começa Aqui <br />
                      Conectamos você ao que há de mais moderno no cuidado com a
                      sua saúde
                    </p>
                  </div>
                  <div className="footer-emailing">
                    <ul>
                      <li>
                        <i className="far fa-envelope"></i>
                        contato@olismulher.com
                      </li>
                      <li>
                        <i className="far fa-flag"></i>47.205.725/0001-00
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-widget mb-40">
                  <div className="footer-title">
                    <h3>Ginecologia</h3>
                  </div>
                  <div className="footer-menu footer-menu-2">
                    <ul>
                      <li>
                        <Link to="/primeiraConsulta">Primeira Consulta</Link>
                      </li>
                      <li>
                        <Link to="/rotinaGinecologica">
                          Rotina Ginecológica
                        </Link>
                      </li>
                      <li>
                        <Link to="/anticoncepcao">Anticoncepção</Link>
                      </li>
                      <li>
                        <Link to="/investigacaoInfertilidade">
                          Investigação de Infertilidade
                        </Link>
                      </li>
                      <li>
                        <Link to="/reposicaoHormonal">Reposição Hormonal</Link>
                      </li>
                      <li>
                        <Link to="/avaliacaoFertilidade">
                          Avaliação de Fertilidade
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="footer-widget mb-40">
                  <div className="footer-title">
                    <h3>Obstetrícia</h3>
                  </div>
                  <div className="footer-menu footer-menu-2">
                    <ul>
                      <li>
                        <Link to="/consultoriaPreNatal">
                          Consultoria Pré-Natal
                        </Link>
                      </li>
                      <li>
                        <Link to="/suplementacaoNaGestacao">
                          Suplementação na Gestação
                        </Link>
                      </li>
                      <li>
                        <Link to="/suplementacaoPosParto">
                          Suplementação Pós-Parto
                        </Link>
                      </li>
                      <li>
                        <Link to="/diabetesGestacional">
                          Diabetes Gestacional
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="footer-bottom pt-25 pb-20">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-copyright footer-copyright-3 text-center">
                  <p>Copyright by@ Mulher Olis - 2023</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </footer>
    </>
  );
};

export default Footer;
